import { GET_SANITY_CONTENT, ADD_SESSION } from '../constants/actionTypes';
import { arrayToObject } from '../utils/arrayHelper';

const initialState = {
  status: '',
  videos: [],
  pdfs: [],
  pages: [],
  portfolios: [],
  images: [],
  companyConfig: {},
  member: {},
  sessionLogs: []
};

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_SESSION.BEGIN:
    case GET_SANITY_CONTENT.BEGIN:
      return {
        ...state,
        status: 'request'
      };
    case ADD_SESSION.SUCCESS:
      return {
        ...state,
        status: 'success'
      };
    case GET_SANITY_CONTENT.SUCCESS:
      return {
        ...state,
        portfolios: [...action.data.portfolios],
        videos: arrayToObject(action.data.videos, '_id'),
        pdfs: arrayToObject(action.data.pdfs, '_id'),
        pages: arrayToObject(action.data.pages, '_id'),
        images: arrayToObject(action.data.images, '_id'),
        companyConfig: { ...action.data.company },
        member: { ...action.data.member },
        sessionLogs: [...action.data.sessionLogs],
        status: 'success'
      };
    case ADD_SESSION.FAILURE:
    case GET_SANITY_CONTENT.FAILURE:
      return {
        ...state,
        status: 'fail'
      };
    default:
      return state;
  }
};
