import {
  GET_COMPANIES,
  CREATE_COMPANY,
  PATCH_COMPANY,
  DELETE_COMPANY,
  BUILD_SANITY,
  DELETE_SANITY
} from '../constants/actionTypes';
import { CLOUD_API_URL } from '../constants/constants';
import firebase from 'firebase/app';
//import React from 'react';

//===================
// GET
//===================
export const getCompaniesBegin = () => ({
  type: GET_COMPANIES.BEGIN
});

export const getCompaniesSuccess = (array) => ({
  type: GET_COMPANIES.SUCCESS,
  array
});

export const getCompaniesFailure = () => ({
  type: GET_COMPANIES.FAILURE
});

//===================
// POST
//===================
export const createCompanyBegin = () => ({
  type: CREATE_COMPANY.BEGIN
});

export const createCompanySuccess = (obj) => ({
  type: CREATE_COMPANY.SUCCESS,
  obj
});

export const createCompanyFailure = () => ({
  type: CREATE_COMPANY.FAILURE
});

export const buildSanityBegin = () => ({
  type: BUILD_SANITY.BEGIN
});

export const buildSanitySuccess = (obj) => ({
  type: BUILD_SANITY.SUCCESS,
  obj
});

export const buildSanityFailure = () => ({
  type: BUILD_SANITY.FAILURE
});

//===================
// PATCH
//===================
export const patchCompanyBegin = () => ({
  type: PATCH_COMPANY.BEGIN
});

export const patchCompanySuccess = (obj) => ({
  type: PATCH_COMPANY.SUCCESS,
  obj
});

export const patchCompanyFailure = () => ({
  type: PATCH_COMPANY.FAILURE
});

//===================
// DELETE
//===================
export const deleteCompanyBegin = () => ({
  type: DELETE_COMPANY.BEGIN
});

export const deleteCompanySuccess = (id) => ({
  type: DELETE_COMPANY.SUCCESS,
  id
});

export const deleteCompanyFailure = () => ({
  type: DELETE_COMPANY.FAILURE
});

export const deleteSanityBegin = () => ({
  type: DELETE_SANITY.BEGIN
});

export const deleteSanitySuccess = (id) => ({
  type: DELETE_SANITY.SUCCESS,
  id
});

export const deleteSanityFailure = () => ({
  type: DELETE_SANITY.FAILURE
});

// -----------------------
// Companies
// -----------------------
// GET all companies
export const getCompanies = () => {
  return (dispatch) => {
    dispatch(getCompaniesBegin());

    // get idToken
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        // fetch
        fetch(`${CLOUD_API_URL}/companies`, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              // handle response error
              console.error(response.statusText);
              dispatch(getCompaniesFailure());
            }
            return response.json();
          })
          .then((data) => {
            // success
            dispatch(getCompaniesSuccess(data.companies));
          })
          .catch((error) => {
            // handle error
            console.error(error);
            dispatch(getCompaniesFailure());
          });
      })
      .catch(function (error) {
        // fail to get idToken
        console.error(error);
        dispatch(getCompaniesFailure());
      });
  };
};

// Create new company
export const createCompany = (data) => {
  return (dispatch) => {
    dispatch(createCompanyBegin());

    // modify data for valid createUser api structure
    let modifiedData = {
      ...data,
      displayName: data.name,
      studioDomain: data.studioDomain,
      isActive: true,
      sanityDataset: 'production',
      sanityProjectId: '',
      sanityToken: '',
      ownerId: ''
    };

    // get idToken
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        // fetch
        fetch(`${CLOUD_API_URL}/companies`, {
          method: 'post',
          headers: {
            Authorization: `Bearer ${idToken}`,
            Accept: 'application/json, text/plain',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(modifiedData)
        })
          .then((response) => {
            if (!response.ok) {
              // handle response error
              console.error(response.statusText);
              dispatch(createCompanyFailure());
            }
            return response.json();
          })
          .then((obj) => {
            // success
            console.log(obj);
            let id = obj.id;
            let isActive = true;
            dispatch(
              createCompanySuccess({
                ...modifiedData,
                id: id,
                isActive: isActive
              })
            );
            {
              dispatch(buildSanityBegin());
              let data = {
                ...modifiedData,
                id: id,
                isActive: true
              };
              // modify data for valid createUser api structure
              let modifiedData2 = {
                displayName: data.name,
                studioDomain: data.studioDomain,
                companyId: data.id
              };
              console.log(modifiedData2);

              // get idToken
              firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                  // fetch
                  fetch(`${CLOUD_API_URL}/build`, {
                    method: 'post',
                    headers: {
                      Authorization: `Bearer ${idToken}`,
                      Accept: 'application/json, text/plain',
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(modifiedData2)
                  })
                    .then((response) => {
                      if (!response.ok) {
                        // handle response error
                        console.error(response.statusText);
                        dispatch(buildSanityFailure());
                      }
                      return response.json();
                    })
                    .then(async (obj) => {
                      // success
                      console.log(obj);
                      const sanityId = obj.id;
                      dispatch(
                        buildSanitySuccess({ ...modifiedData2, id: obj.id })
                      );
                      // Original, improper way to implement
                      // let T = await fetch(
                      //   'https://f2bevents.com:54545/companies/new2',
                      //   {
                      //     method: 'POST',
                      //     headers: {
                      //       'Access-Control-Allow-Origin':
                      //         'https://f2bevents.com:54545',
                      //       'content-type': 'application/json'
                      //     },
                      //     body: JSON.stringify({
                      //       Names: [
                      //         `${modifiedData.displayName};${sanityId};${data.studioDomain}`
                      //       ]
                      //     })
                      //   }
                      // );
                      const companyInformation = {
                        displayName: modifiedData.displayName,
                        sanityId: sanityId,
                        studioDomain: data.studioDomain
                      };
                      let T = await fetch(
                        'https://f2bevents.com:54545/companies/new',
                        {
                          method: 'POST',
                          headers: {
                            'Access-Control-Allow-Origin':
                              'https://f2bevents.com:54545',
                            'content-type': 'application/json'
                          },
                          body: JSON.stringify({
                            companies: [companyInformation]
                          })
                        }
                      );
                      let T2 = await T.json();
                      console.log(T2);
                      let element = document.createElement('a');
                      const file = new Blob([JSON.stringify(T2, null, 2)], {
                        type: 'text/plain'
                      });
                      element.href = URL.createObjectURL(file);
                      element.download = 'status.txt';
                      document.body.appendChild(element); // Required for this to work in FireFox
                      element.click();
                    })
                    .catch((error) => {
                      // handle error
                      console.error(error);
                      dispatch(buildSanityFailure());
                    });
                })
                .catch(function (error) {
                  // fail to get idToken
                  console.error(error);
                  dispatch(buildSanityFailure());
                });
            }
          })
          .catch((error) => {
            // handle error
            console.error(error);
            dispatch(createCompanyFailure());
          });
      })
      .catch(function (error) {
        // fail to get idToken
        console.error(error);
        dispatch(createCompanyFailure());
      });
  };
};

// Patch new company
export const patchCompany = (data) => {
  return (dispatch) => {
    dispatch(patchCompanyBegin());

    let modifiedData = {
      ...data,
      displayName: data.name,
      isActive: data.isActive === 'isActiveTrue' ? true : false
    };
    console.log(modifiedData);

    // check if modifiedData id exists
    if (
      !modifiedData.id ||
      modifiedData.id === '' ||
      modifiedData.sanityProjectId === ''
    ) {
      console.error('No id given');
      dispatch(patchCompanyFailure());
    } else {
      // get idToken
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          // fetch
          fetch(`${CLOUD_API_URL}/companies/${modifiedData.id}`, {
            method: 'patch',
            headers: {
              Authorization: `Bearer ${idToken}`,
              Accept: 'application/json, text/plain',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(modifiedData)
          })
            .then((response) => {
              if (!response.ok) {
                // handle response error
                console.error(response.statusText);
                dispatch(patchCompanyFailure());
              }

              return response.json();
            })
            .then((obj) => {
              // success
              if (obj.success) {
                dispatch(patchCompanySuccess({ ...data }));
              } else {
                console.error('Error in patch');
                dispatch(patchCompanyFailure());
              }
            })
            .catch((error) => {
              // handle error
              console.error(error);
              dispatch(patchCompanyFailure());
            });
        })
        .catch(function (error) {
          // fail to get idToken
          console.error(error);
          dispatch(patchCompanyFailure());
        });
    }
  };
};

// Delete company
export const deleteCompany = (id) => {
  return (dispatch) => {
    dispatch(deleteCompanyBegin());

    // get idToken
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        // fetch
        fetch(`${CLOUD_API_URL}/companies/${id}`, {
          method: 'delete',
          headers: {
            Authorization: `Bearer ${idToken}`,
            Accept: 'application/json, text/plain',
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            if (!response.ok) {
              // handle response error
              console.error(response.statusText);
              dispatch(deleteCompanyFailure());
            } else {
              dispatch(deleteCompanySuccess(id));
            }
          })
          .catch((error) => {
            // handle error
            console.error(error);
            dispatch(deleteCompanyFailure());
          });
      })
      .catch(function (error) {
        // fail to get idToken
        console.error(error);
        dispatch(deleteCompanyFailure());
      });
  };
};

// Build new sanity
export const buildSanity = (data) => {
  return (dispatch) => {
    dispatch(buildSanityBegin());

    // modify data for valid createUser api structure
    let modifiedData = {
      displayName: data.name,
      studioDomain: data.studioDomain,
      companyId: data.id
    };
    console.log(modifiedData);

    // get idToken
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        // fetch
        fetch(`${CLOUD_API_URL}/build`, {
          method: 'post',
          headers: {
            Authorization: `Bearer ${idToken}`,
            Accept: 'application/json, text/plain',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(modifiedData)
        })
          .then((response) => {
            if (!response.ok) {
              // handle response error
              console.error(response.statusText);
              dispatch(buildSanityFailure());
            }
            return response.json();
          })
          .then((obj) => {
            // success
            console.log(obj);
            //let sanityId = obj.id
            dispatch(buildSanitySuccess({ ...modifiedData, id: obj.id }));
          })
          .catch((error) => {
            // handle error
            console.error(error);
            dispatch(buildSanityFailure());
          });
      })
      .catch(function (error) {
        // fail to get idToken
        console.error(error);
        dispatch(buildSanityFailure());
      });
  };
};

// Delete sanity
export const deleteSanity = (id) => {
  return (dispatch) => {
    dispatch(deleteSanityBegin());

    // get idToken
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        // fetch
        fetch(`${CLOUD_API_URL}/build/${id}`, {
          method: 'delete',
          headers: {
            Authorization: `Bearer ${idToken}`,
            Accept: 'application/json, text/plain',
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            if (!response.ok) {
              // handle response error
              console.error(response.statusText);
              dispatch(deleteSanityFailure());
            } else {
              dispatch(deleteSanitySuccess(id));
            }
          })
          .catch((error) => {
            // handle error
            console.error(error);
            dispatch(deleteSanityFailure());
          });
      })
      .catch(function (error) {
        // fail to get idToken
        console.error(error);
        dispatch(deleteSanityFailure());
      });
  };
};
