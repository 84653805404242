import {
  GET_USERS,
  CREATE_USER,
  PATCH_USER,
  DELETE_USER
} from '../constants/actionTypes';
import { CLOUD_API_URL } from '../constants/constants';
import firebase from 'firebase/app';

//===================
// GET
//===================
export const getUsersBegin = () => ({
  type: GET_USERS.BEGIN
});

export const getUsersSuccess = (array) => ({
  type: GET_USERS.SUCCESS,
  array
});

export const getUsersFailure = () => ({
  type: GET_USERS.FAILURE
});

//===================
// POST
//===================
export const createUserBegin = () => ({
  type: CREATE_USER.BEGIN
});

export const createUserSuccess = (obj) => ({
  type: CREATE_USER.SUCCESS,
  obj
});

export const createUserFailure = () => ({
  type: CREATE_USER.FAILURE
});

//===================
// PATCH
//===================
export const patchUserBegin = () => ({
  type: PATCH_USER.BEGIN
});

export const patchUserSuccess = (obj) => ({
  type: PATCH_USER.SUCCESS,
  obj
});

export const patchUserFailure = () => ({
  type: PATCH_USER.FAILURE
});

//===================
// DELETE
//===================
export const deleteUserBegin = () => ({
  type: DELETE_USER.BEGIN
});

export const deleteUserSuccess = (id) => ({
  type: DELETE_USER.SUCCESS,
  id
});

export const deleteUserFailure = () => ({
  type: DELETE_USER.FAILURE
});

// -----------------------
// Users
// -----------------------
// GET all users
export const getUsers = () => {
  return (dispatch) => {
    dispatch(getUsersBegin());

    // get idToken
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        // fetch
        fetch(`${CLOUD_API_URL}/users`, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              // handle response error
              console.error(response.statusText);
              dispatch(getUsersFailure());
            }
            return response.json();
          })
          .then((data) => {
            // success
            console.log(data);
            dispatch(getUsersSuccess(data.users));
          })
          .catch((error) => {
            // handle error
            console.error(error);
            dispatch(getUsersFailure());
          });
      })
      .catch(function (error) {
        // fail to get idToken
        console.error(error);
        dispatch(getUsersFailure());
      });
  };
};

// Create new user
export const createUser = (data) => {
  return (dispatch) => {
    dispatch(createUserBegin());

    // modify data for valid createUser api structure
    let modifiedData = {
      ...data,
      displayName: data.name,
      companyName: data.companyName.label, // this is needed for redux store
      companyId: data.companyName.value,
      isActive: data.isActive === 'isActiveTrue' ? true : false,
      isAbleToAccessCms:
        data.isAbleToAccessCms === 'isAbleToAccessCmsTrue' ? true : false,
      // isAbleToCreateBriefcase:
      //   data.isAbleToCreateBriefcase === 'guestPassTrue' ? true : false,
      // isAbleToCreateGuestPass:
      //   data.isAbleToCreateGuestPass === 'portfolioGuestPassTrue'
      //     ? true
      //     : false,
      team: [],
      portfolio: []
    };
    console.log(modifiedData);

    // get idToken
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        // fetch
        fetch(`${CLOUD_API_URL}/users`, {
          method: 'post',
          headers: {
            Authorization: `Bearer ${idToken}`,
            Accept: 'application/json, text/plain',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(modifiedData)
        })
          .then((response) => {
            if (!response.ok) {
              // handle response error
              console.error(response.statusText);
              dispatch(createUserFailure());
            }
            return response.json();
          })
          .then((obj) => {
            // success
            console.log(obj);
            dispatch(createUserSuccess({ ...modifiedData, id: obj.uid }));
          })
          .catch((error) => {
            // handle error
            console.error(error);
            dispatch(createUserFailure());
          });
      })
      .catch(function (error) {
        // fail to get idToken
        console.error(error);
        dispatch(createUserFailure());
      });
  };
};

// Patch new user
export const patchUser = (data) => {
  return (dispatch) => {
    dispatch(patchUserBegin());

    // NOTE companyName and companyId might return either obj or string so check and assign correctly, ie return "1234556" or {label: 'Company A', value: '456789'}
    // This is due to react-select + material-table combination
    let modifiedData = {
      ...data,
      displayName: data.name,
      companyName: data.companyName.label
        ? data.companyName.label
        : data.companyName, // this is needed for redux store
      companyId: data.companyName.value
        ? data.companyName.value
        : data.companyId,
      isActive: data.isActive === 'isActiveTrue' ? true : false,
      isAbleToAccessCms:
        data.isAbleToAccessCms === 'isAbleToAccessCmsTrue' ? true : false
      // isAbleToCreateBriefcase:
      //   data.isAbleToCreateBriefcase === 'guestPassTrue' ? true : false,
      // isAbleToCreateGuestPass:
      //   data.isAbleToCreateGuestPass === 'portfolioGuestPassTrue' ? true : false
    };
    console.log(modifiedData);

    // check if modifiedData id exists
    if (!modifiedData.id || modifiedData.id === '') {
      console.error('No id given');
      dispatch(patchUserFailure());
    } else {
      // get idToken
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          // fetch
          fetch(`${CLOUD_API_URL}/users/${modifiedData.id}`, {
            method: 'patch',
            headers: {
              Authorization: `Bearer ${idToken}`,
              Accept: 'application/json, text/plain',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(modifiedData)
          })
            .then((response) => {
              if (!response.ok) {
                // handle response error
                console.error(response.statusText);
                dispatch(patchUserFailure());
              }

              return response.json();
            })
            .then((obj) => {
              // success
              if (obj.success) {
                dispatch(patchUserSuccess({ ...modifiedData }));
              } else {
                console.error('Error in patch');
                dispatch(patchUserFailure());
              }
            })
            .catch((error) => {
              // handle error
              console.error(error);
              dispatch(patchUserFailure());
            });
        })
        .catch(function (error) {
          // fail to get idToken
          console.error(error);
          dispatch(createUserFailure());
        });
    }
  };
};

// Patch user automatically through company
export const patchUserAuto = (data) => {
  return (dispatch) => {
    dispatch(patchUserBegin());

    // NOTE companyName and companyId might return either obj or string so check and assign correctly, ie return "1234556" or {label: 'Company A', value: '456789'}
    // This is due to react-select + material-table combination
    let modifiedData = {
      ...data,
      displayName: data.name,
      companyName: data.companyName.label
        ? data.companyName.label
        : data.companyName, // this is needed for redux store
      companyId: data.companyName.value
        ? data.companyName.value
        : data.companyId
      // isActive: data.isActive === 'isActiveTrue' ? true : false,
      // isAbleToAccessCms:
      //   data.isAbleToAccessCms === 'isAbleToAccessCmsTrue' ? true : false,
      // isAbleToCreateBriefcase:
      //   data.isAbleToCreateBriefcase === 'guestPassTrue' ? true : false,
      // isAbleToCreateGuestPass:
      //   data.isAbleToCreateGuestPass === 'portfolioGuestPassTrue' ? true : false
    };
    console.log(modifiedData);

    // check if modifiedData id exists
    if (!modifiedData.id || modifiedData.id === '') {
      console.error('No id given');
      dispatch(patchUserFailure());
    } else {
      // get idToken
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          // fetch
          fetch(`${CLOUD_API_URL}/users/${modifiedData.id}`, {
            method: 'patch',
            headers: {
              Authorization: `Bearer ${idToken}`,
              Accept: 'application/json, text/plain',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(modifiedData)
          })
            .then((response) => {
              if (!response.ok) {
                // handle response error
                console.error(response.statusText);
                dispatch(patchUserFailure());
              }

              return response.json();
            })
            .then((obj) => {
              // success
              if (obj.success) {
                dispatch(patchUserSuccess({ ...modifiedData }));
              } else {
                console.error('Error in patch');
                dispatch(patchUserFailure());
              }
            })
            .catch((error) => {
              // handle error
              console.error(error);
              dispatch(patchUserFailure());
            });
        })
        .catch(function (error) {
          // fail to get idToken
          console.error(error);
          dispatch(createUserFailure());
        });
    }
  };
};

// Delete user (destroy)
export const deleteUser = (id) => {
  return (dispatch) => {
    dispatch(deleteUserBegin());

    // get idToken
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        // fetch
        fetch(`${CLOUD_API_URL}/users/${id}/destroy`, {
          method: 'delete',
          headers: {
            Authorization: `Bearer ${idToken}`,
            Accept: 'application/json, text/plain',
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            if (!response.ok) {
              // handle response error
              console.error(response.statusText);
              dispatch(deleteUserFailure());
            }
            dispatch(deleteUserSuccess(id));
          })
          .catch((error) => {
            // handle error
            console.error(error);
            dispatch(deleteUserFailure());
          });
      })
      .catch(function (error) {
        // fail to get idToken
        console.error(error);
        dispatch(deleteUserFailure());
      });
  };
};
