import { CREATE_COMPANY_METRICS } from '../constants/actionTypes';
import { CLOUD_API_URL } from '../constants/constants';
import firebase from 'firebase/app';

//===================
// POST
//===================
export const createCompanyMetricsBegin = () => ({
  type: CREATE_COMPANY_METRICS.BEGIN
});
export const createCompanyMetricsSuccess = (obj) => ({
  type: CREATE_COMPANY_METRICS.SUCCESS,
  obj
});
export const createCompanyMetricsFailure = () => ({
  type: CREATE_COMPANY_METRICS.FAILURE
});

// -----------------------
// Company Metrics
// -----------------------
// Create company metrics
export const createCompanyMetrics = (company) => {
  return (dispatch) => {
    dispatch(createCompanyMetricsBegin());

    let modifiedData = {
      companyId: company.id
    };

    console.log('modifiedData', modifiedData);

    if (!company.id) {
      console.error('No companyId given');
      dispatch(createCompanyMetricsFailure());
    } else {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          // fetch
          fetch(`${CLOUD_API_URL}/metrics/companies`, {
            method: 'post',
            headers: {
              Authorization: `Bearer ${idToken}`,
              Accept: 'application/json, text/plain',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(modifiedData)
          })
            .then((response) => {
              if (!response.ok) {
                // handle response error
                console.error(response.statusText);
                dispatch(createCompanyMetricsFailure());
              }

              return response.json();
            })
            .then((obj) => {
              // success
              if (obj.success) {
                if (obj.success === true) {
                  console.log('true!');
                  const feedback = {
                    message: 'Metrics updated for ' + company.name + '.'
                  };
                  dispatch(createCompanyMetricsSuccess(feedback));
                } else {
                  console.error('Something went wrong');
                  dispatch(createCompanyMetricsFailure());
                }
              } else {
                console.error('Error in update');
                dispatch(createCompanyMetricsFailure());
              }
            })
            .catch((error) => {
              // handle error
              console.error(error);
              dispatch(createCompanyMetricsFailure());
            });
        })
        .catch(function (error) {
          // fail to generate metrics
          console.error(error);
          dispatch(createCompanyMetricsFailure());
        });
    }
  };
};
