import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import sessionReducer from './sessionReducer';
import portfolioReducer from './portfolioReducer';
import sanityContentReducer from './sanityContentReducer';
import userReducer from './userReducer';
import companyReducer from './companyReducer';
import anonymousReducer from './anonymousReducer';
import sanityAdminTokenReducer from './sanityAdminTokenReducer';
import metricsSummationReducer from './metricsSummationReducer';

export default combineReducers({
  sessionReducer,
  portfolioReducer,
  sanityContentReducer,
  userReducer,
  companyReducer,
  anonymousReducer,
  sanityAdminTokenReducer,
  metricsSummationReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer
});
