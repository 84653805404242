import { connect } from 'react-redux';
import HomePage from './HomePage';
import { getCompanies, getUsers, updateSessionKey } from '../../actions';

const mapStateToProps = (state) => ({
  companies: state.companyReducer.companies,
  users: state.userReducer.users
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanies: () => dispatch(getCompanies()),
    getUsers: () => dispatch(getUsers()),
    updateSessionKey: (key) => dispatch(updateSessionKey(key))
  };
};

const HomePageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);

export default HomePageContainer;
