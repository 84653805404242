import { PATCH_TOKEN } from '../constants/actionTypes';
import { CLOUD_API_URL } from '../constants/constants';
import firebase from 'firebase/app';

//===================
// POST
//===================
export const patchTokenBegin = () => ({
  type: PATCH_TOKEN.BEGIN
});
export const patchTokenSuccess = (obj) => ({
  type: PATCH_TOKEN.SUCCESS,
  obj
});
export const patchTokenFailure = () => ({
  type: PATCH_TOKEN.FAILURE
});

// -----------------------
// Admin Token
// -----------------------
// Update admin token
export const patchToken = (data) => {
  return (dispatch) => {
    dispatch(patchTokenBegin());

    let modifiedData = {
      token: data
    };
    //console.log('modifiedData', modifiedData);

    if (!data) {
      console.error('No token given');
      dispatch(patchTokenFailure());
    } else {
      //dispatch(patchTokenSuccess());
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          // fetch
          fetch(`${CLOUD_API_URL}/secure/token/sanity`, {
            method: 'patch',
            headers: {
              Authorization: `Bearer ${idToken}`,
              Accept: 'application/json, text/plain',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(modifiedData)
          })
            .then((response) => {
              if (!response.ok) {
                // handle response error
                console.error(response.statusText);
                dispatch(patchTokenFailure());
              }

              return response.json();
            })
            .then((obj) => {
              // success
              if (obj.message) {
                dispatch(patchTokenSuccess(obj));
                //dispatch(patchTokenSuccess({ ...data }));
              } else {
                console.error('Error in patch');
                dispatch(patchTokenFailure());
              }
            })
            .catch((error) => {
              // handle error
              console.error(error);
              dispatch(patchTokenFailure());
            });
        })
        .catch(function (error) {
          // fail to get idToken
          console.error(error);
          dispatch(patchTokenFailure());
        });
    }
  };
};
