import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import InputBox from '../../InputBox';
import Button from '../../Button';
import LoginContext from '../../../containers/LoginPage/LoginContext';

const StyledLoginForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  input {
    margin-bottom: 20px;
  }
  button {
    margin-bottom: 10px;
  }

  p {
    color: grey;
    text-align: center;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
`;

const StyledLoginError = styled.div`
  color: red;
`;

const StyledLink = styled.p`
  cursor: pointer;
  text-decoration: underline;
`;

const LoginForm = ({ setForgotPassMode }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginErrorMsg, setLoginErrorMsg] = useState('');
  const firebase = useFirebase();
  const firestore = useFirestore();

  const loginContext = useContext(LoginContext);
  // set in fireStore
  const fireStoreSet = (collection, doc, obj) => {
    // start request status
    loginContext.firestoreRequest();

    // connect firestore collection
    firestore
      .collection(collection)
      .doc(doc)
      .set(obj, { merge: true })
      .then((e) => {
        // success
        console.log('Firestore set success');
        loginContext.firestoreSuccess();
      })
      .catch((error) => {
        console.error(error);
        setLoginErrorMsg(error);
        loginContext.firestoreFailure();
      });
  };

  // handle form submit
  const handleSubmit = (event) => {
    event.preventDefault();

    firebase
      .login({
        email: email,
        password: password
      })
      .then((e) => {
        console.log(e);

        //get current user
        let userId = firebase.auth().currentUser.uid;
        // create random key
        let randomKey = Date.now();

        // update reducer and localstorage sessionKey
        loginContext.updateSessionKey(randomKey);

        // save key and udpate date to user database
        fireStoreSet('users', userId, {
          session_key: randomKey,
          updated_at: firebase.firestore.Timestamp.now()
        });
      })
      .catch((error) => {
        console.error(error);
        setLoginErrorMsg(error.message);
      });
  };

  return (
    <StyledLoginForm onSubmit={handleSubmit}>
      <InputBox
        name="email"
        placeholder="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <InputBox
        name="password"
        placeholder="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button type="submit" text="Login" />
      <StyledLoginError>{loginErrorMsg}</StyledLoginError>
      <StyledLink onClick={() => setForgotPassMode(true)}>
        Reset Password
      </StyledLink>
    </StyledLoginForm>
  );
};

LoginForm.propTypes = {
  setForgotPassMode: PropTypes.func
};

export default LoginForm;
