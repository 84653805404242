/**
 * Input field with adjustable width and height
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyleInputField = styled.input`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-size: 14px;
  border: solid 1px #343434;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px;

  ::placeholder {
    font-size: 14px;
  }
`;

StyleInputField.displayName = 'StyleInputField';

const InputBox = ({
  name,
  value,
  onChange,
  placeholder,
  type,
  width,
  height
}) => (
  <StyleInputField
    name={name}
    value={value}
    placeholder={placeholder}
    width={width}
    height={height}
    type={type}
    onChange={onChange}
  />
);

InputBox.defaultProps = {
  width: '300px',
  height: '42px'
};

InputBox.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.string,
  height: PropTypes.string
};

export default InputBox;
