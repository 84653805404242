import { DEPLOY_TOKEN } from '../constants/actionTypes';
import { CLOUD_API_URL } from '../constants/constants';
import firebase from 'firebase/app';

//===================
// POST
//===================
export const patchDeployBegin = () => ({
  type: DEPLOY_TOKEN.BEGIN
});
export const patchDeploySuccess = (obj) => ({
  type: DEPLOY_TOKEN.SUCCESS,
  obj
});
export const patchDeployFailure = () => ({
  type: DEPLOY_TOKEN.FAILURE
});

// -----------------------
// Admin Token
// -----------------------
// Update admin token
export const patchDeploy = () => {
  return (dispatch) => {
    // console.log('patchDeploy', patchDeploy);
    dispatch(patchDeployBegin());

    //dispatch(patchTokenSuccess());
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        // fetch
        fetch(`${CLOUD_API_URL}/deploy`, {
          method: 'post',
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              // handle response error
              console.error(response.statusText);
              dispatch(patchDeployFailure());
            }

            return response.json();
          })
          .then((obj) => {
            // success
            if (obj.message) {
              dispatch(patchDeploySuccess(obj));
              //dispatch(patchTokenSuccess({ ...data }));
            } else {
              console.error('Error in post');
              dispatch(patchDeployFailure());
            }
          })
          .catch((error) => {
            // handle error
            console.error(error);
            dispatch(patchDeployFailure());
          });
      })
      .catch(function (error) {
        // fail to get idToken
        console.error(error);
        dispatch(patchDeployFailure());
      });
  };
};
