import { connect } from 'react-redux';
import SanityAdminToken from './SanityAdminToken';
import { patchToken } from '../../actions';

const mapStateToProps = (state) => ({
  sanityAdminToken: state.sanityAdminTokenReducer.sanityAdminToken
});

const mapDispatchToProps = (dispatch) => {
  return {
    patchToken: (obj) => dispatch(patchToken(obj))
  };
};

const SanityAdminTokenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SanityAdminToken);

export default SanityAdminTokenContainer;
