import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
  position: relative;
  width: 100%;
  /* background-color: white;
  border: 1px solid #ccc;
  padding: 25px; */
`;

const StyledButton = styled.button`
  color: ${(props) => (props.state === 'delete' ? '#cc0000' : '#000')};
  border-color: ${(props) => (props.state === 'delete' ? '#cc0000' : '#000')};
  background-color: ${(props) =>
    props.state === 'delete' ? '#ffffff' : '#eeeeee'};
  padding: 0.5rem 1rem;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  margin: 0 0.5rem 0 0;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

  :disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
`;
const Deploy = ({ sanityAdminToken, patchDeploy }) => {
  const onDeployUpdate = () => {
    //console.log('token update', token, data);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
        // update firestore
        if (
          window.confirm('Are you sure you want to deploy all active studios?')
        ) {
          patchDeploy();
        }
      }, 100);
    });
  };

  return (
    <StyledSection>
      <StyledButton onClick={onDeployUpdate}>
        Deploy all
        <br /> active studios
      </StyledButton>
      <p style={{ fontStyle: 'italics', fontSize: '10px' }}>
        {sanityAdminToken.message}
      </p>
    </StyledSection>
  );
};

export default Deploy;
