import { connect } from 'react-redux';
import MetricsSummation from './MetricsSummation';
import { createCompanyMetrics } from '../../actions';

const mapStateToProps = (state) => ({
  companies: state.companyReducer.companies,
  metricsSummation: state.metricsSummationReducer.metricsSummation
});

const mapDispatchToProps = (dispatch) => {
  return {
    createCompanyMetrics: (obj) => dispatch(createCompanyMetrics(obj))
  };
};

const MetricsSummationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MetricsSummation);

export default MetricsSummationContainer;
