import {
  PURGE_ANONYMOUS_ACCOUNTS,
  GET_ANONYMOUS_ACCOUNTS
} from '../constants/actionTypes';

const initialState = {
  anonymousAccounts: {}
};

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ANONYMOUS_ACCOUNTS.SUCCESS:
      return {
        ...state,
        anonymousAccounts: action.obj
      };
    case PURGE_ANONYMOUS_ACCOUNTS.SUCCESS:
      return {
        ...state,
        anonymousAccounts: action.obj
      };
    default:
      return state;
  }
};
