import React, { useState } from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
  position: relative;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  padding: 25px;
`;

const StyledButton = styled.button`
  color: ${(props) => (props.state === 'delete' ? '#cc0000' : '#000')};
  border-color: ${(props) => (props.state === 'delete' ? '#cc0000' : '#000')};
  background-color: ${(props) =>
    props.state === 'delete' ? '#ffffff' : '#eeeeee'};
  padding: 0.5rem 1rem;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  margin: 0 0.5rem 0 0;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

  :disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
`;
const SanityAdminToken = ({ sanityAdminToken, patchToken }) => {
  const [token, setToken] = useState(null);

  const onTokenChange = (e) => {
    setToken(e.target.value);
  };

  const onTokenUpdate = (data) => {
    //console.log('token update', token, data);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        data.submitted = true;

        // Validation logic
        if (!token || token === '') {
          return reject();
        }
        delete data.submitted;

        resolve();

        // update firestore
        if (
          window.confirm('Are you sure you want to update the Security Token?')
        ) {
          patchToken(token);
        }
      }, 100);
    });
  };

  return (
    <StyledSection>
      <h1>Update Sanity Admin Token</h1>
      <p>
        This tools updates the secure admin token used for Sanity project
        creation. This token is associated with the{' '}
        <strong>sanity@f2bservices.com</strong> account.
      </p>
      <p>
        To get the auth token, software team will need to run this command on
        their Sanity login session: <code>sanity debug --secrets</code>
      </p>
      <p>
        New token:
        <br />
        <textarea
          onChange={onTokenChange}
          style={{ width: '75%', height: '50px' }}
        />
      </p>
      <StyledButton onClick={onTokenUpdate}>Update token</StyledButton>
      <p style={{ fontStyle: 'italics', fontSize: '10px' }}>
        {sanityAdminToken.message}
      </p>
    </StyledSection>
  );
};

export default SanityAdminToken;
