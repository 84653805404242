// email valid check
export const emailIsValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

// https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
// NOTE may note be super reliable so need backend to also check
export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const userDataValidate = (data) => {
  console.log('Inspect: ', data);
  if (!data.name || data.name === '') {
    console.error('Missing name');
    return false;
  }
  if (!emailIsValid(data.email)) {
    console.error('Missing valid email');
    return false;
  }
  if (!data.role) {
    console.error('Missing role');
    return false;
  }
  if (!data.companyName || data.companyName === '') {
    console.error('Missing companyName');
    return false;
  }
  if (data.isAbleToAccessCms === null || data.isAbleToAccessCms === undefined) {
    console.error('Missing isAbleToAccessCms');
    return false;
  }
  if (!data.isActive) {
    console.error('Missing isActive');
    return false;
  }
  if (
    data.isAbleToCreateBriefcase === null ||
    data.isAbleToCreateBriefcase === undefined
  ) {
    console.error('Missing isAbleToCreateBriefcase');
    return false;
  }
  if (
    data.isAbleToCreateGuestPass === null ||
    data.isAbleToCreateGuestPass === undefined
  ) {
    console.error('Missing isAbleToCreateGuestPass');
    return false;
  }

  return true;
};

export const companyDataValidate = (data, companies, additionalCheck) => {
  if (!data.name || data.name === '') {
    console.error('Missing name');
    return false;
  }
  if (!isValidHttpUrl(data.studioDomain)) {
    console.error('Missing valid studioDomain');
    return false;
  }

  /*
  let domainTaken = false;

  // check domain name is used already
  domainTaken = companies.some((obj) => {
    let sameDomain =
      obj.studioDomain
        .trim()
        .localeCompare(data.studioDomain.trim(), undefined, {
          sensitivity: 'accent'
        }) === 0;

    // skip same id
    if (data.id === obj.id && sameDomain && data.studioDomain !== '') {
      console.log('......false');
      return false;
    }
    return sameDomain;
  });

  if (domainTaken) {
    // taken, not valid
    console.error('Domain already used');
    return false;
  }
    if (!data.isActive) {
    console.error('Missing isActive');
    return false;
  }
*/

  // additional check for patch
  if (additionalCheck) {
    if (!data.sanityDataset || data.sanityDataset === '') {
      console.error('Missing sanityDataset');
      return false;
    }
    if (!data.sanityProjectId || data.sanityProjectId === '') {
      console.error('Missing sanityProjectId');
      return false;
    }
  }

  return true;
};

export const sanityBuildValidate = (data) => {
  if (!data.id || data.id === '') {
    console.error('Missing company id');
    return false;
  }
  if (!data.name || data.name === '') {
    console.error('Missing name');
    return false;
  }
  if (!isValidHttpUrl(data.studioDomain)) {
    console.error('Missing valid studioDomain');
    return false;
  }

  return true;
};
