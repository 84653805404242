import { connect } from 'react-redux';
import { compose } from 'redux';
import { isLoaded, isEmpty, firestoreConnect } from 'react-redux-firebase';
import App from './App';
import {
  updateSessionKey,
  getSanityContent,
  resetPortfolioMode
} from '../../actions';

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  userInfo: state.firestore.ordered.users,
  companyInfo: state.firestore.ordered.companies,
  fireStoreSessionStatus: state.sessionReducer.fireStoreSessionStatus,
  sessionKey: state.sessionReducer.sessionKey
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateSessionKey: (key) => dispatch(updateSessionKey(key)),
    getSanityContent: () => dispatch(getSanityContent()),
    resetPortfolioMode: () => dispatch(resetPortfolioMode())
  };
};

const AppContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    let collection = [];
    let authReady = isLoaded(props.auth) && !isEmpty(props.auth);

    // Return empty if there are no auth
    if (!authReady) return collection;
    else {
      collection = [{ collection: 'users', doc: props.auth.uid }];
    }

    // grab company id once userInfo is filled
    if (
      props.userInfo !== undefined &&
      props.userInfo[0].company_id !== undefined
    ) {
      collection = [
        { collection: 'users', doc: props.auth.uid },
        {
          collection: 'companies',
          doc: props.userInfo[0].company_id
        }
      ];
    }
    // Return user info using auth.uid
    return collection;
  })
)(App);

export default AppContainer;
