import {
  SET_PORTFOLIO_CUSTOMER_INFO,
  SET_PORTFOLIO_ID,
  SET_PORTFOLIO_MODE,
  RESET_PORTFOLIO_INFO,
  SET_PORTFOLIO_SESSION_ID
} from '../constants/actionTypes';

const initialState = {
  mode: '',
  chosenPortfolioId: '',
  customerInfo: {},
  status: '',
  sessionId: ''
};

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PORTFOLIO_CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: action.obj
      };
    case SET_PORTFOLIO_ID:
      return {
        ...state,
        chosenPortfolioId: action.id
      };
    case SET_PORTFOLIO_MODE:
      return {
        ...state,
        mode: action.mode
      };
    case SET_PORTFOLIO_SESSION_ID:
      console.log(action);
      return {
        ...state,
        sessionId: action.id
      };
    case RESET_PORTFOLIO_INFO:
      return initialState;
    default:
      return state;
  }
};
