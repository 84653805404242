import React, { useState } from 'react';
import styled from 'styled-components';
import InputBox from '../../InputBox';
import Button from '../../Button';
import { urlFor } from '../../../utils/sanityHelper';

const StyledEngageCustomerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 900px;
  width: 1421px;
  height: 725px;
  border-radius: 50px;
  background-color: white;
`;

const StyledEngageCustomerBoxLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  padding: 25px;

  img {
    width: calc(100% - 48px);
    height: auto;
  }
`;

const StyledEngageRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  height: 100%;
`;

const StyledCustomerForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .customer-form-name {
    margin-bottom: 20px;
  }

  input {
    margin-bottom: 10px;
  }

  p {
    color: blue;
    align-self: flex-end;
    font-size: 14px;
    margin: 0;
    padding: 0;
    cursor: pointer;

    :hover {
      text-decoration: 'underline';
    }
  }

  hr {
    color: grey;
    width: 100%;
  }
`;

const StyledCCEmail = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 128px;
  overflow-y: auto;
`;

const StyledEngageRightMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 50px;
  width: 400px;
`;

// TODO make reusuable component of menu texts
const StyledMenuText = styled.div`
  font-size: 20px;
  color: ${(props) => (props.disabled ? 'grey' : 'black')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  :hover {
    text-decoration: ${(props) => (props.disabled ? 'none' : 'underline')};
  }
`;

const EngageCustomerInfo = ({ setPortfolioCustomerInfo, createSession }) => {
  const initialForm = {
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    ccEmails: []
  };
  const [ccEmails, setCCEmails] = useState([]);
  const [formData, updateFormData] = useState({ ...initialForm });
  //const [isDisabled, setIsDisabled] = useState(true);

  // Update cc emails in array
  const udpateCCEmail = (index, e) => {
    console.log(e);

    // create new array with ccEmails content
    let newArr = [...ccEmails];

    // update ccEmail at certain index
    newArr[index] = e.target.value.trim();
    setCCEmails(newArr);
  };

  const handleChange = (e) => {
    // update formData
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim()
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // combine formData and ccEmails
    let newFormData = { ...formData, ccEmails: [...ccEmails] };

    setPortfolioCustomerInfo(newFormData);
    createSession(newFormData);
  };
  return (
    <StyledCustomerForm>
      <div className="customer-form-name">Customer Information</div>
      <InputBox
        name="firstName"
        width="426px"
        height="32px"
        placeholder="First Name"
        type="text"
        onChange={handleChange}
      />
      <InputBox
        name="lastName"
        width="426px"
        height="32px"
        placeholder="Last Name"
        type="text"
        onChange={handleChange}
      />
      <InputBox
        name="company"
        width="426px"
        height="32px"
        placeholder="Company"
        type="text"
        onChange={handleChange}
      />
      <InputBox
        name="email"
        width="426px"
        height="32px"
        placeholder="Email"
        type="email"
        onChange={handleChange}
      />
      <p onClick={() => setCCEmails([...ccEmails, ''])}>Add Cc</p>
      <StyledCCEmail>
        {ccEmails.map((emails, index) => (
          <InputBox
            key={'email-cc-' + index} // TODO use unique id instead of index
            name="ccemail"
            width="350px"
            height="32px"
            placeholder="Email"
            type="email"
            onChange={(e) => udpateCCEmail(index, e)}
          />
        ))}
      </StyledCCEmail>
      <hr />
      <Button
        text="Start"
        height="50px"
        onClick={handleSubmit}
        disabled={false}
      />
    </StyledCustomerForm>
  );
};

const EngageCustomerBox = ({
  setMode,
  logout,
  setPortfolioCustomerInfo,
  createSession,
  companyConfig,
  images
}) => {
  return (
    <StyledEngageCustomerBox>
      <StyledEngageCustomerBoxLogo>
        {companyConfig.logo !== undefined && (
          <img
            src={urlFor(images[companyConfig.logo.asset._ref])
              .quality(80)
              .width(400)
              .url()}
            alt="logo"
            type="image"
          />
        )}
      </StyledEngageCustomerBoxLogo>
      <StyledEngageRight>
        <EngageCustomerInfo
          setPortfolioCustomerInfo={setPortfolioCustomerInfo}
          createSession={createSession}
        />
        <StyledEngageRightMenu>
          <StyledMenuText onClick={() => setMode('')}>Back</StyledMenuText>
          <StyledMenuText onClick={logout}>Logout</StyledMenuText>
        </StyledEngageRightMenu>
      </StyledEngageRight>
    </StyledEngageCustomerBox>
  );
};

export default EngageCustomerBox;
