import { connect } from 'react-redux';
import LoginPage from './LoginPage';
import {
  updateSessionKey,
  firestoreRequest,
  firestoreSuccess,
  firestoreFailure
} from '../../actions/sessionActions';

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSessionKey: (key) => dispatch(updateSessionKey(key)),
    firestoreRequest: () => dispatch(firestoreRequest()),
    firestoreSuccess: () => dispatch(firestoreSuccess()),
    firestoreFailure: () => dispatch(firestoreFailure())
  };
};

const LoginPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);

export default LoginPageContainer;
