import { connect } from 'react-redux';
import EngagePage from './EngagePage';
import {
  updateSessionKey,
  setPortfolioMode,
  setPortfolioId,
  setPortfolioCustomerInfo,
  getSanityContent,
  resetPortfolioMode,
  setPortfolioSessionId,
  addSessionToSanity
} from '../../actions';

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  portfolio: state.sanityContentReducer.portfolios,
  companyConfig: state.sanityContentReducer.companyConfig,
  images: state.sanityContentReducer.images,
  member: state.sanityContentReducer.member,
  mode: state.portfolioReducer.mode,
  chosenPortfolioId: state.portfolioReducer.chosenPortfolioId
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateSessionKey: (key) => dispatch(updateSessionKey(key)),
    setPortfolioMode: (mode) => dispatch(setPortfolioMode(mode)),
    setPortfolioId: (id) => dispatch(setPortfolioId(id)),
    setPortfolioCustomerInfo: (obj) => dispatch(setPortfolioCustomerInfo(obj)),
    getSanityContent: () => dispatch(getSanityContent()),
    resetPortfolioMode: () => dispatch(resetPortfolioMode()),
    setPortfolioSessionId: (id) => dispatch(setPortfolioSessionId(id)),
    addSessionToSanity: (session) => dispatch(addSessionToSanity(session))
  };
};

const EngagePageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EngagePage);

export default EngagePageContainer;
