import { connect } from 'react-redux';
import AnonymousPurge from './AnonymousPurge';
import { purgeAnonymousAccounts, getAnonymousAccounts } from '../../actions';

const mapStateToProps = (state) => ({
  anonymousAccounts: state.anonymousReducer.anonymousAccounts
});

const mapDispatchToProps = (dispatch) => {
  return {
    purgeAnonymousAccounts: (durationInDays) =>
      dispatch(purgeAnonymousAccounts(durationInDays)),
    getAnonymousAccounts: (durationInDays) =>
      dispatch(getAnonymousAccounts(durationInDays))
  };
};

const AnonymousPurgeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnonymousPurge);

export default AnonymousPurgeContainer;
