import { GET_SANITY_CONTENT, ADD_SESSION } from '../constants/actionTypes';
import { client } from '../utils/client';

// GET
export const getSanityContentBegin = () => ({
  type: GET_SANITY_CONTENT.BEGIN
});

export const getSanityContentSuccess = (data) => ({
  type: GET_SANITY_CONTENT.SUCCESS,
  data
});

export const getSanityContentFailure = () => ({
  type: GET_SANITY_CONTENT.FAILURE
});

// ADD
export const addSessionBegin = () => ({
  type: ADD_SESSION.BEGIN
});

export const addSessionSuccess = () => ({
  type: ADD_SESSION.SUCCESS
});

export const addSessionFailure = () => ({
  type: ADD_SESSION.FAILURE
});

// GET sanity content
export const getSanityContent = () => {
  return (dispatch, getState) => {
    const state = getState();

    // get uid
    const uid = state.firebase.auth.uid;
    // Begin santify GET
    dispatch(getSanityContentBegin());
    // Get all contents of sanity needed in portfolio, ignore drafts
    const query = `*[][1]{
      "pdfs": *[_type == 'sanity.fileAsset'  && !(_id in path('drafts.**'))]{_id, _type, url, extension, mimeType}, 
      "images": *[_type == 'sanity.imageAsset'  && !(_id in path('drafts.**'))]{_id, _type, url}, 
      "pages": *[_type == 'page' && !(_id in path('drafts.**'))],
      "videos": *[_type == 'mux.videoAsset' && status == 'ready'  && !(_id in path('drafts.**'))],
      "portfolios": *[_type == 'portfolio' && !(_id in path('drafts.**'))],
      "company": *[_type == 'company-config'  && !(_id in path('drafts.**'))] | order(_updatedAt desc) [0],
      "member": *[_type == 'member' && uid == '${uid}'  && !(_id in path('drafts.**'))][0],
      "sessionLogs": *[_type == 'session-log' && creator->uid == '${uid}'  && !(_id in path('drafts.**'))]
    }`;
    const params = {};

    client()
      .fetch(query, params)
      .then((data) => {
        // Success
        dispatch(getSanityContentSuccess(data));
      })
      .catch((error) => {
        // Fail
        dispatch(getSanityContentFailure());
        console.log('Error', error);
      });
  };
};

// ADD session info to sanity
export const addSessionToSanity = (session) => {
  return (dispatch, getState) => {
    // Begin ADD
    dispatch(addSessionBegin());

    const state = getState();

    // get member
    const member = state.sanityContentReducer.member;

    // convert firestore UTC seconds to datetime
    let date = Date(1000 * session.session_date.seconds);

    const sanitySession = {
      title: `${session.creator} with ${
        session.customer_name
      } on ${date.toString()}`,
      _type: 'session-log',
      creator: {
        _ref: member._id, // NOTE This is not the same as uid, it is id specific to sanity
        _type: 'reference',
        _weak: true // 2024-01: Shifting to weak reference per Sanity feedback
      },
      portfolio: {
        _ref: session.portfolio_id,
        _type: 'reference',
        _weak: true // 2024-01: Shifting to weak reference per Sanity feedback
      },
      session_date: date,
      session_id: session.session_id
    };

    client()
      .create(sanitySession)
      .then(() => {
        // Success
        dispatch(addSessionSuccess());
        console.log('Sanity session create success');
      })
      .catch((error) => {
        dispatch(addSessionFailure());
        console.error('Error', error);
      });
  };
};
