/**
 * Button with custom styling
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  cursor: pointer;
  border: none;

  :disabled {
    background-color: rgb(170, 204, 238);
    color: #666666;
  }
`;

StyledButton.displayName = 'StyledButton';

const StyledButtonText = styled.div`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
`;

const Button = ({
  text,
  fontSize,
  color,
  width,
  height,
  backgroundColor,
  onClick,
  disabled,
  type
}) => {
  return (
    <StyledButton
      onClick={onClick}
      backgroundColor={backgroundColor}
      width={width}
      height={height}
      type={type}
      disabled={disabled}
    >
      <StyledButtonText color={color} fontSize={fontSize}>
        {text}
      </StyledButtonText>
    </StyledButton>
  );
};

Button.defaultProps = {
  type: 'button',
  disabled: false,
  backgroundColor: 'rgb(7, 104, 176)',
  width: '300px',
  height: '42px',
  fontSize: '16px',
  color: 'white'
};

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  fontSize: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  backgroundColor: PropTypes.string
};

export default Button;
