import { connect } from 'react-redux';
import UserList from './UserList';
import { createUser, patchUser, deleteUser } from '../../actions';

const mapStateToProps = (state) => ({
  userReducer: state.userReducer,
  companies: state.companyReducer.companies
});

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (obj) => dispatch(createUser(obj)),
    patchUser: (obj) => dispatch(patchUser(obj)),
    deleteUser: (uid) => dispatch(deleteUser(uid))
  };
};

const UserListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserList);

export default UserListContainer;
