import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFirebase } from 'react-redux-firebase';
import UserList from '../UserList';
import CompanyList from '../CompanyList';
import GPL from '../BulkGuestPassList';
import EMB from '../EmbedCreation';
import AnonymousPurge from '../AnonymousPurge';
import MetricsSummation from '../MetricsSummation';
import SanityAdminToken from '../SanityAdminToken';
import Deploy from '../Deploy';

const StyledHomePage = styled.div`
  background-color: ${(props) =>
    props.mode === 'training' ? 'rgb(170, 204, 238)' : '#eee'};
  padding: 1rem;
  color: #000;
  h1 {
    text-transform: uppercase;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  background-color: #ffffff;
  padding: 1rem;
  border: 1px solid #000;
`;

const StyledNav = styled.nav`
  ul {
    list-style-type: none;
    padding: 1rem 0 0 0;
    margin: 0;

    li {
      cursor: pointer;
      text-transform: uppercase;
      display: inline-block;
      margin: 0 1rem 0 0;
      color: #333;
      text-decoration: none;
      padding: 0.5rem 1.5rem;
      font-weight: bold;
      border-top: 1px solid #000;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
      border-bottom: 0;
      &:hover {
        background-color: #333;
        color: #fff;
      }
      &.current {
        cursor: default;
        background-color: #000;
        color: #fff;
      }
    }
  }
`;

const StyledButton = styled.button`
  padding: 0.5rem 1rem;
  border: 1px solid #000;
  display: inline-block;
  margin: 0 0.5rem 0 0;
`;

const StyledSection = styled.section`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: 90%;
  min-height: 0;
  section {
    margin: 5px;
    min-height: 0;
  }
`;

const StyledHelp = styled.section`
  width: 10%;
  min-width: 20px;
  padding: 25px 0 0 0;
  min-height: 900px;
  h1 {
    padding: 0;
    margin: 0;
  }
  h2 {
    font-size: 1rem;
    font-weight: bold;
  }
  ol {
    padding: 0 10px 0 15px;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      border: none;
    }
  }
`;

const HomePage = ({ updateSessionKey, getUsers, getCompanies }) => {
  const firebase = useFirebase();
  const [currentTab, setCurrentTab] = useState('company');

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const toggleTab = (tab) => {
    console.log('[TAB]', tab);
    setCurrentTab(tab);
  };

  const logout = () => {
    console.log('[LOGOUT]');
    firebase.logout();
    // clear local storage and redux session key
    updateSessionKey('');
  };

  return (
    <StyledHomePage>
      <StyledButton onClick={logout} style={{ float: 'right' }}>
        Logout
      </StyledButton>
      <h1>Super Admin / Viewing {currentTab}</h1>
      <StyledNav>
        <ul>
          <li
            className={currentTab === 'company' ? 'current' : null}
            onClick={() => toggleTab('company')}
          >
            Company
          </li>
          <li
            className={currentTab === 'user' ? 'current' : null}
            onClick={() => toggleTab('user')}
          >
            User
          </li>
          <li
            className={currentTab === 'additional' ? 'current' : null}
            onClick={() => toggleTab('additional')}
          >
            Additional Tools
          </li>
          <li
            className={currentTab === 'gpl' ? 'current' : null}
            onClick={() => toggleTab('gpl')}
          >
            GPL (Isolated Crashes Tab)
          </li>
          <li
            className={currentTab === 'emb' ? 'current' : null}
            onClick={() => toggleTab('emb')}
          >
            emb (Under Testing)
          </li>
        </ul>
      </StyledNav>
      <StyledContainer>
        <StyledHelp>
          <h1>Overview</h1>
          {currentTab === 'company' && (
            <>
              <p>This is a view of all companies in the system.</p>
              <p>There are four steps to create a new company studio build:</p>
              <ol>
                <li>
                  <strong>Create a Company</strong> ([+] icon in top right).
                  This creates a company in the database.
                </li>
                <li>
                  <strong>Create Sanity Build</strong>. This button will appear
                  next to the company listing. This creates the Sanity project
                  foundation.
                </li>
                <li>
                  <strong>Add Sanity API Token</strong>. Follow the link
                  provided to generate the API token. Update the company list
                  with this API token.
                </li>
                <li>
                  <strong>Deploy all studios</strong>. If you are superadmin,
                  click on the button below to deploy all studios:
                  <Deploy />
                  Note: A new studio will need to have a MUX API key added. This
                  will need to be provided by software.
                  <br />
                  <br />
                  <a
                    href="https://sanity.f2bportfolio.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    &gt; View studio
                  </a>
                </li>
              </ol>
              <h2>
                Warning: Deleting a Sanity build will remove all content. There
                is no going back.
              </h2>
            </>
          )}
          {currentTab === 'user' && (
            <>
              <p>This is a view of all users in the system.</p>
              <p>
                You can add/remove users as well and reassociate users to
                different roles.
              </p>
              <p>
                All companies require a unique user (based on email address).
              </p>
              <h2>
                Warning: Do not reassociate your role to a non-Super admin tier.
                This will prevent you from accessing this interface.
              </h2>
            </>
          )}
          {currentTab === 'additional' && (
            <>
              <p>
                These are additional tools to be used to help administer the
                Portfolios.
              </p>
            </>
          )}
        </StyledHelp>
        <StyledSection>
          {currentTab === 'company' && <CompanyList />}
          {currentTab === 'user' && <UserList />}
          {currentTab === 'additional' && (
            <>
              <MetricsSummation />
              <AnonymousPurge />
              <SanityAdminToken />
            </>
          )}
          {currentTab === 'gpl' && <GPL />}
          {currentTab === 'emb' && <EMB />}
        </StyledSection>
      </StyledContainer>
    </StyledHomePage>
  );
};

export default HomePage;
