import React, { Fragment, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import Button from '../../Button';
import { urlFor } from '../../../utils/sanityHelper';

const StyledEngageChoiceBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 900px;
  width: 1421px;
  height: 725px;
  border-radius: 50px;
  background-color: white;
`;

const StyledEngageChoiceLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  padding: 25px;

  img {
    width: calc(100% - 48px);
    height: auto;
  }
`;

const StyledEngageRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  height: 100%;

  p {
    font-size: 48px;
    text-align: center;
  }

  button {
    margin-top: 34px;
  }
`;

const StyledEngageRightMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 50px;
  width: 80%;
`;

const StyledMenuText = styled.div`
  font-size: 20px;
  color: ${(props) => (props.disabled ? 'grey' : 'black')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  :hover {
    text-decoration: ${(props) => (props.disabled ? 'none' : 'underline')};
  }
`;

const EngageChoose = ({
  dropdownOptions,
  select,
  setSelect,
  setMode,
  setPortfolioId
}) => {
  // react-select styles
  const styles = {
    control: (base) => ({
      ...base,
      width: '300px'
    }),
    container: (base) => ({
      ...base,
      textAlign: 'left',
      fontSize: '22px'
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0
    })
  };

  return (
    <Fragment>
      <p>{select === null ? 'Choose Portfolio' : select.label}</p>
      <Select
        styles={styles}
        onChange={(select) => setSelect(select)}
        options={dropdownOptions}
        placeholder="Choose Portfolio"
      />
      <Button
        text="Start"
        onClick={() => {
          setPortfolioId(select.value);
          setMode('default');
        }}
        disabled={select === null}
      />
    </Fragment>
  );
};

const EngageChoiceBox = ({
  dropdownOptions,
  error,
  setMode,
  logout,
  setPortfolioId,
  companyConfig,
  images
}) => {
  const [select, setSelect] = useState(null);

  return (
    <StyledEngageChoiceBox>
      <StyledEngageChoiceLogo>
        {companyConfig.logo !== undefined && (
          <img
            src={urlFor(images[companyConfig.logo.asset._ref])
              .quality(80)
              .width(400)
              .url()}
            alt="logo"
            type="image"
          />
        )}
      </StyledEngageChoiceLogo>
      <StyledEngageRight>
        <EngageChoose
          dropdownOptions={dropdownOptions}
          error={error}
          setMode={setMode}
          select={select}
          setSelect={setSelect}
          setPortfolioId={setPortfolioId}
        />
        <StyledEngageRightMenu>
          <StyledMenuText
            onClick={() => {
              setPortfolioId(select.value);
              setMode('training');
            }}
            disabled={select === null}
          >
            Training
          </StyledMenuText>
          <StyledMenuText onClick={logout}>Logout</StyledMenuText>
          <StyledMenuText disabled={select === null}>Dashboard</StyledMenuText>
        </StyledEngageRightMenu>
      </StyledEngageRight>
    </StyledEngageChoiceBox>
  );
};

export default EngageChoiceBox;
