import {
  GET_COMPANIES,
  CREATE_COMPANY,
  PATCH_COMPANY,
  DELETE_COMPANY,
  BUILD_SANITY,
  DELETE_SANITY
} from '../constants/actionTypes';

const initialState = {
  companies: []
};

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANIES.SUCCESS:
      return {
        ...state,
        companies: [...action.array]
      };
    case CREATE_COMPANY.SUCCESS:
      return {
        ...state,
        companies: [...state.companies, action.obj]
      };
    case PATCH_COMPANY.SUCCESS:
      return {
        ...state,
        companies: state.companies.map((obj) => {
          // find modified/patched obj
          if (obj.id === action.obj.id) {
            return action.obj;
          }
          return obj;
        })
      };
    case DELETE_COMPANY.SUCCESS:
      return {
        ...state,
        companies: state.companies.filter((obj) => {
          return obj.id !== action.id;
        })
      };
    case BUILD_SANITY.SUCCESS:
      return {
        ...state,
        companies: state.companies.map((obj) => {
          // find modified/patched obj
          if (obj.id === action.obj.companyId) {
            return {
              ...obj,
              sanityProjectId: action.obj.id
            };
          }
          return obj;
        })
      };
    case DELETE_SANITY.SUCCESS:
      return {
        ...state,
        companies: state.companies.map((obj) => {
          // find modified/patched obj
          if (obj.id === action.id) {
            // clear sanityProjectId
            return {
              ...obj,
              sanityProjectId: ''
            };
          }
          return obj;
        })
      };
    default:
      return state;
  }
};
