import { GUEST_BASE_URL } from '../../constants/constants';
import SanityClient from '@sanity/client';
import firebase from 'firebase/app';
import InputBox from '../../components/InputBox';
import { useFirestoreConnect } from 'react-redux-firebase';
import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Report } from '@material-ui/icons';

export const GPL = ({ companies, users }) => {
  useFirestoreConnect([
    { collection: 'companies', storeAs: 'allCompanies' },
    { collection: 'users', storeAs: 'allUsers' }
  ]);
  const [eventName, setEventName] = useState('');
  const [CSVFile, setCSVFile] = useState();
  // const [CSVarray, setCSVarray] = useState();
  const [UserSelect, setUserSelect] = useState();
  const [UserSelectup, setUserSelectUP] = useState(false);
  const [Port, setPort] = useState(null);
  const [PortSelect, setPortSelect] = useState();

  // useEffect(()=>{console.log("CSVArr1",CSVFile);if(!CSVFile)return null;console.log("CSVArr2");let alpha = [];
  // // CSVFile.map((ele, i)=>{if(ele.split(',').length>1){
  // //   let beta = ele.split(',');
  // //   alpha.push({Client:beta[0],event:eventName,company:beta[1],Company:companies.filter((ele)=>ele.id === b.current.value)[0].name, email:beta[2]})
  // // }
  // // console.log('Array',alpha)
  // // setCSVarray(alpha);
  // },[CSVFile])//},[CSVFile])

  const db = firebase.firestore();

  const GP = db.collection('guest_passes');
  // db.doc() need collection
  // GP.doc() Just endpoint
  //bat.set(Ref)
  // const Test = () =>{
  //     let bat = db.batch();
  //     bat.set(GP.doc(`F2BTest-TestBefore-JonesBakery-${uuidv4()}`),{Comment:"TestSuccess1",is_active:true,name:"Jones",company_id:'w1xCuF4FXghe0AxEWnp0',is_portfolio_view:true,portfolio_id:"b3443d66-accd-4656-80c4-a9896b0babb5",user_name:'Bulk'});
  //     bat.set(GP.doc(`F2BTest2-TestBefore-JonesBakery-${uuidv4()}`),{Comment:"TestSuccess2",is_active:true,name:"Jones2",company_id:'w1xCuF4FXghe0AxEWnp0',is_portfolio_view:true,portfolio_id:"b3443d66-accd-4656-80c4-a9896b0babb5",user_name:'Bulk'});
  //     bat.commit();
  // }

  let b = useRef(null);
  let UserS = useRef(null);
  let PortS = useRef(null);
  useEffect(() => {
    console.log('Setting UserSelect');
    setUserSelect(
      <select ref={UserS}>
        {b.current
          ? users
              .filter((ele) => {
                return ele.companyId === b.current.value;
              })
              .map((ele) => {
                return (
                  <option key={ele.id} value={ele.id}>
                    {ele.email}
                  </option>
                );
              })
          : null}
      </select>
    );
  }, [UserSelectup, users]);
  useEffect(() => {
    console.log('Setting PortSelect');
    console.log(Port?.portfolios);
    setPortSelect(
      <select ref={PortS}>
        {Port
          ? Port.portfolios.map((ele) => {
              return (
                <option key={ele.id} value={ele._id}>
                  {ele.title}
                </option>
              );
            })
          : null}
      </select>
    );
  }, [Port]);
  const client = () => {
    if (
      companies.filter((ele) => ele.id === b.current.value)[0]
        .sanityProjectId === '' ||
      companies.filter((ele) => ele.id === b.current.value)[0]
        .sanityProjectId === undefined
    ) {
      console.error(
        'ERROR: Trying to create SanityClient without valid dataset and projectId'
      );
    }
    let sanity = new SanityClient({
      dataset: companies.filter((ele) => ele.id === b.current.value)[0]
        .sanityDataset,
      projectId: companies.filter((ele) => ele.id === b.current.value)[0]
        .sanityProjectId,
      token: companies.filter((ele) => ele.id === b.current.value)[0]
        .sanityToken,
      useCdn: false,
      ignoreBrowserTokenWarning: true
    });

    return sanity;
  };
  const query = `*[][1]{"portfolios": *[_type == 'portfolio' && !(_id in path('drafts.**'))],
"members": *[_type == 'member'  && !(_id in path('drafts.**'))]}`;
  const params = {};
  //Tested and known to work as expected, b is an array of ports
  const PortFetch = async () => {
    console.log('Start Fetch');
    let b = await client().fetch(query, params);
    console.log('MEMCHECK: ', b);
    setPort(b);
    return b;
  };
  console.log(companies);
  console.log(users);
  // let PortSelect;
  // useEffect(()=>{
  //   console.log("PS");
  //   PortSelect = ((companies.filter((ele)=>ele.id === b.current.value)[0].sanityProjectId !== "" && companies.filter((ele)=>ele.id === b.current.value)[0].sanityProjectId !== undefined)?
  //   (() =>{PortFetch(); return (<select>
  //     {/* {companies.map((ele)=>{return(<option value={ele.id}>{ele.name}</option>)})} */}
  //   </select>)}):null),[b.current && b.current.value]
  // })

  // const guestPass = {
  //   company_id: userInfo[0].company_id,
  //   portfolio_id: chosenPortfolio._id,
  //   is_portfolio_view: isPortfolioSend,
  //   pages: pages,
  //   user_id: auth.uid,
  //   user_sanity_id: member._id,
  //   creation_date: firestore.Timestamp.now(),
  //   user_name: member.name,
  //   customer_name: customerInfo.firstName + ' ' + customerInfo.lastName,
  //   customer_email: customerInfo.email
  // };let url = `${GUEST_BASE_URL}${docRef.id}`;addGuestPassToSanity({ ...guestPass, url: url });

  const addGuestPassToSanity = (guestPass) => {
    console.log('URL INSPECT: ', guestPass);
    let date = new Date(1000 * guestPass.creation_date.seconds);
    // add 1 week
    let expireDate = new Date(
      1000 * (guestPass.creation_date.seconds + 604800)
    );
    const sanityGuestPass = {
      title: `Guest pass for ${guestPass.customer_name} - ${date.toString()}`,
      _type: 'guest-pass',
      creator: {
        _ref: guestPass.user_sanity_id, // NOTE This is not the same as uid, it is id specific to sanity
        _type: 'reference'
      },
      creation_date: date,
      expire_date: expireDate,
      share_url: guestPass.url,
      viewer_log: guestPass.viewer_log,
      portfolio: {
        _ref: guestPass.portfolio_id,
        _type: 'reference'
      }
    };
    client()
      .create(sanityGuestPass)
      .catch((eve) => {
        console.log('ERROR: ', eve);
        console.log('Name: ', guestPass.customer_name);
      });
  };

  const Test2 = () => {
    console.log('step 1');
    let bat = db.batch();
    let alpha = [];
    console.log('step1.2: ', CSVFile);
    CSVFile.map((ele, i) => {
      if (ele.split(',').length > 1) {
        let beta = ele.split(',');
        console.log(beta);
        alpha.push({
          Client: beta[0],
          event: eventName,
          company: beta[1],
          Company: companies.filter((ele) => ele.id === b.current.value)[0]
            .name,
          email: beta[2]
        });
        return {
          Client: beta[0],
          event: eventName,
          company: beta[1],
          Company: companies.filter((ele) => ele.id === b.current.value)[0]
            .name,
          email: beta[2]
        };
      }
      return null;
    });
    let CSVarray = alpha;
    console.log(CSVarray);
    let temp = [...CSVarray];
    //console.log(PortS.current.value);
    //console.log()
    // console.log(users.filter((ele)=>{return ele.id === UserS.current.value})[0].name)
    // console.log(Port.members)
    // console.log(users)

    // console.log(etemp)
    // console.log(Port.members.filter((ele)=>{return ele.email === etemp})[0]._id)
    // console.log(users.filter((ele)=>{return ele.id === UserS.current.value})[0].id)
    console.log('step 2: ', temp);
    let etemp = users.filter((ele) => {
      return ele.id === UserS.current.value;
    })[0].email;
    CSVarray.map((ele, i) => {
      let creation_date = firebase.firestore.Timestamp.now();

      let url = `${ele.Client}-${ele.event}-${ele.company}-${uuidv4()}`;
      console.log(`step 3.${i}: `, url);
      bat.set(GP.doc(url), {
        is_active: true,
        customer_email: ele.email,
        customer_name: ele.Client,
        company_id: b.current.value,
        is_portfolio_view: true,
        portfolio_id: PortS.current.value,
        user_name: users.filter((ele) => {
          return ele.id === UserS.current.value;
        })[0].name,
        user_id: users.filter((ele) => {
          return ele.id === UserS.current.value;
        })[0].id,
        viewer_log: [
          {
            email: ele.email,
            name: ele.Client,
            _type: 'viewerLog',
            _key: Math.random().toString(36).substr(2, 7)
          }
        ],
        creation_date: creation_date,
        user_sanity_id: Port.members.filter((ele) => {
          return ele.email === etemp;
        })[0]._id
      }); //portfolio_id:PortS.current.value,user_name:users.filter((ele)=>{return ele.id === UserS.current.value})[0].name, user_id:users.filter((ele)=>{return ele.id === UserS.current.value})[0].id,creation_date:creation_date,user_sanity_id:Port.members.filter((ele)=>{return ele.email === users.filter((ele)=>{return ele.uid === UserS.current.value})[0].email})[0]._id}
      temp[i].GP_Url = `${GUEST_BASE_URL}${url}`;
      temp[i].creation_date = creation_date;
      return ele;
    });
    console.log('step 4: ', temp);
    //setCSVarray(temp);
    console.log(temp);

    bat.commit();
    temp.map((ele) => {
      console.log('step5');
      addGuestPassToSanity({
        company_id: b.current.value,
        portfolio_id: PortS.current.value,
        is_portfolio_view: true,
        //  pages: undefined,
        user_id: users.filter((ele) => {
          return ele.id === UserS.current.value;
        })[0].id,
        user_sanity_id: Port.members.filter((ele) => {
          return ele.email === etemp;
        })[0]._id,
        creation_date: ele.creation_date,
        user_name: users.filter((ele) => {
          return ele.id === UserS.current.value;
        })[0].name,
        customer_name: ele.Client,
        customer_email: ele.email,
        url: ele.GP_Url
      });
      return ele;
    });
    let report = '';
    temp.forEach((ele) => {
      delete ele.event;
      delete ele.Company;
      delete ele.creation_date;
      return ele;
    });
    Object.keys(temp[0]).map((ele) => {
      report = report + ele + ',';
      return ele;
    });
    console.log('Report: ', Report);
    report += '\n';
    temp.map((ele) => {
      Object.values(ele).map((ele2) => {
        report = report + ele2 + ',';
        return ele2;
      });
      report += '\n';
      console.log('Report: ', Report);
      return ele;
    });
    const reportblob = new Blob([report], { type: 'text/csv' });
    downloadBlob(reportblob, 'Testexport.csv');
  };

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;

    let download = filename;
    a.download = download;
    const clickHandler = (ele) => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        ele.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler(a), false);
    a.click();
    return;
  };

  return (
    <>
      <select
        onChange={async (eve) => {
          setUserSelectUP((u) => {
            return !u;
          });
          let t = PortFetch();
          console.log(t);
          /*setPort(t);*/ console.log(b.current.selectedIndex);
          console.log(companies.filter((ele) => ele.id === b.current.value)[0]);
        }}
        ref={b}
      >
        {companies.map((ele) => {
          return (
            <option key={ele.id} value={ele.id}>
              {ele.name}
            </option>
          );
        })}
      </select>
      {UserSelect}
      {PortSelect}
      <h2>Import CSV of guestpasses</h2>
      <InputBox
        name="eventName"
        placeholder="Event name"
        type="text"
        onChange={(e) => setEventName(e.target.value)}
      />
      <InputBox
        name="CSVFile"
        type="file"
        onChange={(e) => {
          console.log(e.target.files);
          var fr = new FileReader();
          fr.onloadend = (ev) => {
            console.log('!!END!!', fr.result);
            let allLines = fr.result.split(/\r\n|\n/);
            console.log('All Lines: ', allLines);
            setCSVFile(allLines);
          };
          fr.readAsText(e.target.files[0]);
        }}
      />
      <button onClick={Test2}>TestGP</button>
    </>
  );
};
