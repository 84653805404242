import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MaterialTable, { MTableEditField } from 'material-table';
import Select from 'react-select';
import tableIcons from '../../utils/materialTableHelper';
import { userDataValidate } from '../../utils/validateHelper';

const StyledUserList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledUserContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledTablesContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  h2 {
    margin-left: 10px;
  }
`;

const UserList = ({
  userReducer,
  companies,
  createUser,
  patchUser,
  deleteUser
}) => {
  const [userTableData, setUserTableData] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [companyOptions, setCompanyOptions] = useState([]);

  // react-select styles
  const styles = {
    control: (base) => ({
      ...base,
      width: '100%',
      border: 0
    }),
    container: (base) => ({
      ...base,
      textAlign: 'left'
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0
    })
  };

  // Create user data for table
  useEffect(() => {
    let users = userReducer.users;
    if (users.length > 0) {
      let options = [];
      let data = [];
      let count = 0;
      users.forEach((obj) => {
        // count total user
        count++;

        // Need to replace true/false to unique string to use lookup in material-table
        data.push({
          ...obj,
          isActive: obj.isActive ? 'isActiveTrue' : 'isActiveFalse',
          isAbleToAccessCms: obj.isAbleToAccessCms
            ? 'isAbleToAccessCmsTrue'
            : 'isAbleToAccessCmsFalse'
          // isAbleToCreateBriefcase: obj.isAbleToCreateBriefcase
          //   ? 'guestPassTrue'
          //   : 'guestPassFalse',
          // isAbleToCreateGuestPass: obj.isAbleToCreateGuestPass
          //   ? 'portfolioGuestPassTrue'
          //   : 'portfolioGuestPassFalse'
        });

        options.push({
          value: obj.id,
          label: obj.name !== '' ? obj.name : obj.email
        });
      });

      setUserCount(count);
      // set user table data to use in material-table
      setUserTableData(data);
    }
  }, [userReducer.users, companies]);

  // Create user options for select/dropdown
  useEffect(() => {
    if (companies.length > 0) {
      let options = [];

      companies.forEach((obj) => {
        options.push({
          value: obj.id,
          label: obj.name
        });
      });
      setCompanyOptions(options);
    }
  }, [companies]);

  // Add user
  const onRowAddUser = (data) => {
    console.log('add', data);
    data.isAbleToCreateGuestPass = true; //GP/briefcase non-distinctive patch
    data.isAbleToCreateBriefcase = true; //GP/briefcase non-distinctive patch
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        data.submitted = true;

        if (!userDataValidate(data)) {
          return reject();
        }
        delete data.submitted;

        resolve();

        // update firestore
        createUser({ ...data });
      }, 1000);
    });
  };

  // Update User
  const onRowUpdateUser = (data) => {
    console.log('update', data);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        data.submitted = true;

        if (!userDataValidate(data)) {
          return reject();
        }
        delete data.submitted;
        resolve();
        // update firestore
        patchUser({ ...data });
      }, 1000);
    });
  };

  // Delete User
  const onRowDeleteUser = (data) => {
    console.log('delete', data);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
        // update firestore
        deleteUser(data.id);
      }, 1000);
    });
  };

  //--------------------
  // Columns
  //--------------------

  // users
  const tableColumns = [
    {
      title: 'Company',
      field: 'companyName',
      required: true,
      editComponent: (props) => {
        // default value

        let chosenOption = {
          label: props.rowData.companyName,
          value: props.rowData.companyId
        };
        return (
          <Select
            hideSelectedOptions={true}
            styles={styles}
            options={companyOptions}
            onChange={props.onChange}
            defaultValue={chosenOption}
          />
        );
      }
    },
    { title: 'Name', field: 'name', required: true },
    { title: 'Email', field: 'email', required: true },
    {
      title: 'Role',
      field: 'role',
      required: true,
      lookup: {
        superadmin: 'Super admin',
        admin: 'Admin',
        manager: 'Manager',
        user: 'User'
      }
    },
    {
      title: 'Is active',
      field: 'isActive',
      required: true,
      lookup: {
        isActiveTrue: 'True',
        isActiveFalse: 'False'
      }
    },
    {
      title: 'Is able to access CMS',
      field: 'isAbleToAccessCms',
      required: true,
      lookup: {
        isAbleToAccessCmsTrue: 'True',
        isAbleToAccessCmsFalse: 'False'
      }
    },
    //GP/briefcase non-distinctive patch
    // {
    //   title: 'Able to create briefcase',
    //   field: 'isAbleToCreateBriefcase',
    //   type:"boolean",
    //   //required: true,
    //   // lookup: {
    //   //   guestPassTrue: 'True',
    //   //   guestPassFalse: 'False'
    //   // },
    //    initialEditValue:true,
    //   // editComponent:(props)=>{
    //   //   defaultValue = (props.value || guestPassTrue)
    //   // },
    //GP/briefcase non-distinctive patch
    // },
    // {
    //   title: 'Able to create guest pass',
    //   field: 'isAbleToCreateGuestPass',
    //   type:"boolean",
    //   initialEditValue:false,
    //   //required: true,
    //   // lookup: {
    //   //   portfolioGuestPassTrue: 'True',
    //   //   portfolioGuestPassFalse: 'False'
    //   // }
    // },
    {
      title: 'F2B Account',
      field: 'F2B',
      type: 'boolean',
      initialEditValue: false
      //required: true,
      // lookup: {
      //   portfolioGuestPassTrue: 'True',
      //   portfolioGuestPassFalse: 'False'
      // }
    }
  ];

  return (
    <StyledUserList>
      <StyledUserContent>
        <StyledTablesContainer>
          <MaterialTable
            minRows={20}
            options={{
              draggable: false,
              search: false,
              filtering: true,
              sorting: true,
              actionsColumnIndex: -1,
              pageSize: 20,
              pageSizeOptions: [0, 5, 10, 20, 40],
              rowStyle: (rowData) => ({
                color: rowData.disable ? '#AAA' : '#000'
              }),
              addRowPosition: 'first'
            }}
            editable={{
              isEditable: (rowData) => !rowData.disable,
              isDeletable: (rowData) => !rowData.disable,
              onRowAdd: onRowAddUser,
              onRowUpdate: onRowUpdateUser,
              onRowDelete: onRowDeleteUser
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'Loading...'
              }
            }}
            components={{
              EditField: (props) => {
                if (props.columnDef.required && props.rowData.submitted) {
                  return <MTableEditField {...props} error label="Required" />;
                }
                return <MTableEditField {...props} />;
              }
            }}
            icons={tableIcons}
            columns={tableColumns}
            data={userTableData.map((obj) => ({ ...obj }))} // NOTE MaterialTable mutates data passed so give copy of userTableData
            title={`Member list (${userCount})`}
          />
        </StyledTablesContainer>
      </StyledUserContent>
    </StyledUserList>
  );
};

export default UserList;
