import {
  PURGE_ANONYMOUS_ACCOUNTS,
  GET_ANONYMOUS_ACCOUNTS
} from '../constants/actionTypes';
import { CLOUD_API_URL } from '../constants/constants';
import firebase from 'firebase/app';

//===================
// GET
//===================
export const getAnonymousAccountsBegin = () => ({
  type: GET_ANONYMOUS_ACCOUNTS.BEGIN
});
export const getAnonymousAccountsSuccess = (obj) => ({
  type: GET_ANONYMOUS_ACCOUNTS.SUCCESS,
  obj
});
export const getAnonymousAccountsFailure = () => ({
  type: GET_ANONYMOUS_ACCOUNTS.FAILURE
});

//===================
// POST
//===================
export const purgeAnonymousAccountsBegin = () => ({
  type: PURGE_ANONYMOUS_ACCOUNTS.BEGIN
});
export const purgeAnonymousAccountsSuccess = (obj) => ({
  type: PURGE_ANONYMOUS_ACCOUNTS.SUCCESS,
  obj
});
export const purgeAnonymousAccountsFailure = () => ({
  type: PURGE_ANONYMOUS_ACCOUNTS.FAILURE
});

// -----------------------
// Anonymous Account Purges
// -----------------------
// Get list of accounts
export const getAnonymousAccounts = (durationInDays) => {
  return (dispatch) => {
    dispatch(getAnonymousAccountsBegin());
    if (!durationInDays) {
      console.error('No time duration given');
      dispatch(getAnonymousAccountsFailure());
    } else {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          console.log(`${CLOUD_API_URL}/users/anonymous/by/${durationInDays}`);
          // fetch
          fetch(`${CLOUD_API_URL}/users/anonymous/by/${durationInDays}`, {
            method: 'get',
            headers: {
              Authorization: `Bearer ${idToken}`,
              Accept: 'application/json, text/plain',
              'Content-Type': 'application/json'
            }
          })
            .then((response) => {
              if (!response.ok) {
                // handle response error
                console.error(response.statusText);
                dispatch(getAnonymousAccountsFailure());
              }

              return response.json();
            })
            .then((obj) => {
              // success
              if (obj) {
                dispatch(getAnonymousAccountsSuccess(obj));
              } else {
                console.error('Error in patch');
                dispatch(getAnonymousAccountsFailure());
              }
            })
            .catch((error) => {
              // handle error
              console.error(error);
              dispatch(getAnonymousAccountsFailure());
            });
        })
        .catch(function (error) {
          // fail to get idToken
          console.error(error);
          dispatch(getAnonymousAccountsFailure());
        });
    }
  };
};

// Purge accounts
export const purgeAnonymousAccounts = (data) => {
  return (dispatch) => {
    dispatch(purgeAnonymousAccountsBegin());
    let modifiedData = {
      durationInDays: data
    };
    console.log('purgeAnonymousAccounts: modifiedData', modifiedData);

    if (!data) {
      console.error('No time duration given');
      dispatch(purgeAnonymousAccountsFailure());
    } else {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          // fetch
          fetch(`${CLOUD_API_URL}/users/anonymous/purge`, {
            method: 'post',
            headers: {
              Authorization: `Bearer ${idToken}`,
              Accept: 'application/json, text/plain',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(modifiedData)
          })
            .then((response) => {
              console.log('first');
              if (!response.ok) {
                // handle response error
                console.error(response.statusText);
                dispatch(purgeAnonymousAccountsFailure());
              }
              return response.json();
            })
            .then((obj) => {
              console.log('second');
              // success
              if (obj.message) {
                console.log('Success purge message', obj.message);
                dispatch(purgeAnonymousAccountsSuccess(obj));
              } else {
                console.error('Error in post');
                dispatch(purgeAnonymousAccountsFailure());
              }
            })
            .catch((error) => {
              // handle error
              console.error(error);
              dispatch(purgeAnonymousAccountsFailure());
            });
        })
        .catch(function (error) {
          // fail to get idToken
          console.error(error);
          dispatch(purgeAnonymousAccountsFailure());
        });
    }
  };
};
