import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
  position: relative;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  padding: 25px;
`;

const StyledButton = styled.button`
  color: ${(props) => (props.state === 'delete' ? '#cc0000' : '#000')};
  border-color: ${(props) => (props.state === 'delete' ? '#cc0000' : '#000')};
  background-color: ${(props) =>
    props.state === 'delete' ? '#ffffff' : '#eeeeee'};
  padding: 0.5rem 1rem;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  margin: 0 0.5rem 0 0;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

  :disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
`;

const AnonymousPurge = ({
  anonymousAccounts,
  purgeAnonymousAccounts,
  getAnonymousAccounts
}) => {
  const [duration, setDuration] = useState(30);
  const [anonymousAccountCount, setAnonymousAccountCount] = useState(0);

  const onDurationChange = (e) => {
    setDuration(e.target.value);
  };

  useEffect(() => {
    getAnonymousAccounts(duration);
  }, [getAnonymousAccounts, duration]);

  // Create user options for select/dropdown
  useEffect(() => {
    if (anonymousAccounts) {
      console.log('anonymousAccounts', anonymousAccounts);
      setAnonymousAccountCount(anonymousAccounts.count);
    }
  }, [anonymousAccounts]);

  const onPurge = (data) => {
    console.log('onPurge', duration, data);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        data.submitted = true;

        // Validation logic
        if (!duration || duration === '') {
          return reject();
        }
        delete data.submitted;

        resolve();

        // update firestore
        if (
          window.confirm(
            `Are you sure you want to purge these ${anonymousAccountCount} users?`
          )
        ) {
          purgeAnonymousAccounts(duration);
        }
      }, 100);
    });
  };

  return (
    <StyledSection>
      <h1>Purge Anonymous Accounts</h1>
      <p>
        This tool purges anonymous accounts from Firestore to keep the list
        manageable.
      </p>
      <p>
        Purge <strong>{anonymousAccountCount}</strong> anonymous accounts older
        than
        <select
          name="duration"
          id="duration"
          onChange={onDurationChange}
          style={{ padding: '10px', margin: '0 10px' }}
        >
          <option value="30">30</option>
          <option value="25">25</option>
          <option value="20">20</option>
          <option value="15">15</option>
          <option value="10">10</option>
        </select>
        days.
      </p>
      <StyledButton onClick={onPurge}>Purge Anonymous Accounts</StyledButton>
      <p style={{ fontStyle: 'italics', fontSize: '10px' }}>
        {anonymousAccounts.message}
      </p>
    </StyledSection>
  );
};

export default AnonymousPurge;
