import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { isLoaded, isEmpty, useFirebase } from 'react-redux-firebase';
import LoginPage from '../LoginPage';
import HomePage from '../HomePage';
import EngagePage from '../EngagePage';
import './App.css';
import { setSanityInfo } from '../../utils/client';

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector((state) => state.firebase.auth);
  // Wait till auth is loaded
  if (!isLoaded(auth)) return <div>Loading...</div>;
  return children;
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
// http://react-redux-firebase.com/docs/recipes/auth.html
function PrivateRoute({ children, ...rest }) {
  const auth = useSelector((state) => state.firebase.auth);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const App = ({
  updateSessionKey,
  fireStoreSessionStatus,
  resetPortfolioMode,
  sessionKey,
  getSanityContent,
  userInfo,
  companyInfo,
  auth
}) => {
  const firebase = useFirebase();

  useEffect(() => {
    console.log('app loaded');
    // get localstorage sessionKey on first load
    let key = localStorage.getItem('sessionKey');

    // NOTE: on first time, sessionKey doesn't exists so set to emtpy string
    if (key === null) {
      key = '';
    }
    // update sessionKey in redux store
    updateSessionKey(key);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set sanity id from companyInfo
  useEffect(() => {
    let authReady = isLoaded(auth) && !isEmpty(auth);
    if (authReady && companyInfo !== undefined) {
      // grab sanity ids
      let clientInfo = {
        dataset: companyInfo[0].sanity_dataset,
        projectId: companyInfo[0].sanity_project_id,
        token: companyInfo[0].sanity_token
      };

      //set sanity id info
      setSanityInfo(clientInfo);

      //get sanity content
      //getSanityContent();
    }
    //setClient
  }, [companyInfo, getSanityContent, auth]);

  // Detect userInfo update
  // NOTE This will update everytime the user database updates in firebase
  useEffect(() => {
    let authReady = isLoaded(auth) && !isEmpty(auth);
    // compare firestore session key with localstorage session key
    if (
      authReady &&
      fireStoreSessionStatus === 'idle' &&
      userInfo !== undefined &&
      userInfo[0].session_key !== undefined &&
      userInfo[0].session_key.toString() !== sessionKey.toString()
    ) {
      //if not equal force logout
      firebase.auth().signOut(); // NOTE: THis may not be necessary
      firebase.logout();
      // clear sessionKey in local storage and redux session key
      updateSessionKey('');
      // reset portfolioReducer
      resetPortfolioMode();
      console.log('Detected user signing in on another device, logging out');
    }
  }, [userInfo, fireStoreSessionStatus, auth]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={LoginPage} />
          <Route path="/login" exact component={LoginPage} />
          <AuthIsLoaded>
            <PrivateRoute exact path="/home">
              <HomePage />
            </PrivateRoute>
            <PrivateRoute exact path="/engage">
              <EngagePage />
            </PrivateRoute>
          </AuthIsLoaded>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
