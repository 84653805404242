import { connect } from 'react-redux';
import Deploy from './Deploy';
import { patchDeploy } from '../../actions';

const mapStateToProps = (state) => ({
  sanityAdminToken: state.sanityAdminTokenReducer.sanityAdminToken
});

const mapDispatchToProps = (dispatch) => {
  return {
    patchDeploy: () => dispatch(patchDeploy())
  };
};

const DeployContainer = connect(mapStateToProps, mapDispatchToProps)(Deploy);

export default DeployContainer;
