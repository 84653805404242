import {
  UPDATE_SESSION_KEY,
  SET_FIRESTORE_SESSION_KEY
} from '../constants/actionTypes';

export const updateSession = (sessionKey) => ({
  type: UPDATE_SESSION_KEY,
  sessionKey
});

// helpers for firestore
export const firestoreRequest = () => ({
  type: SET_FIRESTORE_SESSION_KEY.BEGIN
});

export const firestoreSuccess = () => ({
  type: SET_FIRESTORE_SESSION_KEY.SUCCESS
});

export const firestoreFailure = () => ({
  type: SET_FIRESTORE_SESSION_KEY.FAILURE
});

export const updateSessionKey = (sessionKey) => {
  // update localStorage
  localStorage.setItem('sessionKey', sessionKey);

  return (dispatch) => {
    dispatch(updateSession(sessionKey));
  };
};
