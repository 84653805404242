import React, { useEffect } from 'react';
import styled from 'styled-components';
import LoginBox from '../../components/_Login/LoginBox';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import LoginContext from './LoginContext';

const StyledLoginPage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(7, 104, 176);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginPage = ({
  auth,
  profile,
  history,
  updateSessionKey,
  firestoreRequest,
  firestoreSuccess,
  firestoreFailure
}) => {
  // helper funcs to use in child components
  const helper = {
    updateSessionKey,
    firestoreRequest,
    firestoreSuccess,
    firestoreFailure
  };

  // Check auth
  useEffect(() => {
    // If auth isLoaded and not empty then push user to /home
    if (isLoaded(auth) && !isEmpty(auth)) {
      history.push('/home');
    }
  }, [auth, history]);

  return (
    <StyledLoginPage>
      <LoginContext.Provider value={helper}>
        <LoginBox profile={profile} updateSessionKey={updateSessionKey} />
      </LoginContext.Provider>
    </StyledLoginPage>
  );
};

export default LoginPage;
