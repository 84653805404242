import { generateActions } from '../utils/reduxHelper';

// session
export const UPDATE_SESSION_KEY = 'UPDATE_SESSION_KEY';
export const SET_FIRESTORE_SESSION_KEY = generateActions(
  'SET_FIRESTORE_SESSION_KEY'
);

// portfolio
export const SET_PORTFOLIO_CUSTOMER_INFO = 'SET_PORTFOLIO_CUSTOMER_INFO';
export const SET_PORTFOLIO_ID = 'SET_PORTFOLIO_ID';
export const SET_PORTFOLIO_MODE = 'SET_PORTFOLIO_MODE';
export const RESET_PORTFOLIO_INFO = 'RESET_PORTFOLIO_INFO';
export const SET_PORTFOLIO_SESSION_ID = 'SET_PORTFOLIO_SESSION_ID';

// sanity contents
export const GET_SANITY_CONTENT = generateActions('GET_SANITY_CONTENT');
export const ADD_SESSION = generateActions('ADD_SESSION');

// user
export const GET_USERS = generateActions('GET_USERS');
export const CREATE_USER = generateActions('CREATE_USER');
export const PATCH_USER = generateActions('PATCH_USER');
export const DELETE_USER = generateActions('DELETE_USER');

// company
export const GET_COMPANIES = generateActions('GET_COMPANIES');
export const CREATE_COMPANY = generateActions('CREATE_COMPANY');
export const PATCH_COMPANY = generateActions('PATCH_COMPANY');
export const DELETE_COMPANY = generateActions('DELETE_COMPANY');
export const BUILD_SANITY = generateActions('BUILD_SANITY');
export const DELETE_SANITY = generateActions('DELETE_SANITY');

// token
export const PATCH_TOKEN = generateActions('PATCH_TOKEN');

// deploy
export const DEPLOY_TOKEN = generateActions('DEPLOY_TOKEN');

// anonymous account purge
export const PURGE_ANONYMOUS_ACCOUNTS = generateActions(
  'PURGE_ANONYMOUS_ACCOUNTS'
);
export const GET_ANONYMOUS_ACCOUNTS = generateActions('GET_ANONYMOUS_ACCOUNTS');

// metrics summation
export const CREATE_COMPANY_METRICS = generateActions('CREATE_COMPANY_METRICS');
