import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
  position: relative;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  padding: 25px;
`;

const StyledButton = styled.button`
  color: ${(props) => (props.state === 'delete' ? '#cc0000' : '#000')};
  border-color: ${(props) => (props.state === 'delete' ? '#cc0000' : '#000')};
  background-color: ${(props) =>
    props.state === 'delete' ? '#ffffff' : '#eeeeee'};
  padding: 0.5rem 1rem;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  margin: 0 0.5rem 0 0;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

  :disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
`;

const MetricsSummation = ({
  metricsSummation,
  companies,
  createCompanyMetrics
}) => {
  const [companySelectionData, setCompanySelectionData] = useState([]);
  const [company, setCompany] = useState(null);

  // Create company data for drop down
  useEffect(() => {
    if (companies.length > 0) {
      let data = [];
      companies.forEach((obj) => {
        // add userName field
        data.push({
          ...obj,
          isActive: obj.isActive ? 'isActiveTrue' : 'isActiveFalse',
          tableData: {
            id: obj.id
          }
        });
      });

      setCompanySelectionData(data);
    }
  }, [companies]);

  const onCompanyChange = (e) => {
    setCompany({
      name: e.target.options[e.target.selectedIndex].text,
      id: e.target.value
    });
  };

  const onGenerateCompanyMetrics = (data) => {
    console.log('onGenerateCompanyMetrics', company, data);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        data.submitted = true;
        // Validation logic
        if (!company.id || company.id === '') {
          return reject();
        }
        delete data.submitted;

        resolve();

        // update firestore
        if (
          window.confirm(
            `Are you sure you want to generate new metrics for this ${company.name}?`
          )
        ) {
          createCompanyMetrics(company);
        }
      }, 100);
    });
  };

  return (
    <StyledSection>
      <h1>Generate New Metrics</h1>
      <p>
        This tool gives manual control to generate new metrics for a company.
      </p>
      <p>
        <select name="metrics" onChange={onCompanyChange}>
          <option key="nothing">Select a company</option>
          {companySelectionData.map((obj, index) => (
            <option key={index} value={obj.id}>
              {obj.name}
            </option>
          ))}
        </select>
      </p>
      <StyledButton onClick={onGenerateCompanyMetrics}>
        Generate New Company Metrics
      </StyledButton>
      <p style={{ fontStyle: 'italics', fontSize: '10px' }}>
        {metricsSummation.message}
      </p>
    </StyledSection>
  );
};

export default MetricsSummation;
