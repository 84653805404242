import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MaterialTable, { MTableEditField } from 'material-table';
import Select from 'react-select';
//import { useFirebase, useFirestore } from 'react-redux-firebase';
import tableIcons from '../../utils/materialTableHelper';
import {
  companyDataValidate,
  sanityBuildValidate,
  userDataValidate
} from '../../utils/validateHelper';

const StyledCompanyList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCompanyContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledTablesContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  h2 {
    margin-left: 10px;
  }
`;

const StyledButton = styled.button`
  color: ${(props) => (props.state === 'delete' ? '#cc0000' : '#000')};
  border-color: ${(props) => (props.state === 'delete' ? '#cc0000' : '#000')};
  background-color: ${(props) =>
    props.state === 'delete' ? '#ffffff' : '#eeeeee'};
  padding: 0.5rem 1rem;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  margin: 0 0.5rem 0 0;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

  :disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
`;

const CompanyList = ({
  companies,
  users,
  createCompany,
  patchCompany,
  deleteCompany,
  patchUser,
  buildSanity,
  deleteSanity
}) => {
  const [companyTableData, setCompanyTableData] = useState([]);
  const [companyCount, setCompanyCount] = useState(0);
  const [userOptions, setUserOptions] = useState([]);

  // react-select styles
  const styles = {
    control: (base) => ({
      ...base,
      width: '100%',
      border: 0
    }),
    container: (base) => ({
      ...base,
      textAlign: 'left'
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0
    })
  };

  // Create company data for table
  useEffect(() => {
    if (companies.length > 0) {
      let data = [];
      let count = 0;
      companies.forEach((obj) => {
        // count total companies
        count++;

        // get user from userlist
        let user = users.find((user) => user.id === obj.ownerId);

        // add userName field
        // console.log(obj);
        data.push({
          ...obj,
          userName: user ? user.name : '',
          isActive: obj.isActive ? 'isActiveTrue' : 'isActiveFalse',
          tableData: {
            id: obj.id
          }
        });
      });

      setCompanyCount(count);
      // set company table data to use in material-table
      setCompanyTableData(data);
    }
  }, [companies, users]);

  // Create user options for select/dropdown
  useEffect(() => {
    if (users.length > 0) {
      let options = [];

      users.forEach((obj) => {
        options.push({
          value: obj.id,
          label: obj.name
        });
      });
      setUserOptions(options);
    }
  }, [users]);

  // Add company
  const onRowAddCompany = (data) => {
    // console.log('add', data);
    data.studioDomain = NCDomain.current.value;
    data.isAbleToCreateGuestPass = true; //GP/briefcase non-distinctive patch
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        data.submitted = true;

        if (!companyDataValidate(data, companies, false)) {
          return reject();
        }

        delete data.submitted;

        resolve();

        // update firestore
        createCompany(data);
      }, 1000);
    });
  };

  // Update company
  const onRowUpdateCompany = (data) => {
    return new Promise((resolve, reject) => {
      // console.log('update', data);
      if (
        data.isAbleToCreateGuestPass === false &&
        data.isAbleToCreateGuestPass !== undefined
      ) {
        let count = 0;
        users.forEach((ele) => {
          // console.log(ele);
          if (ele.companyId === data.id) count += 1;
        });
        if (
          window.confirm(
            'Warning, by removing the ability to create guest passes, you will be modifying ' +
              count +
              ' users. This action cannot be undone automatically, do you wish to continue?'
          )
        ) {
          setTimeout(() => {
            data.submitted = true;

            // extra valid check for sanityDataset and sanityProjectId
            if (!companyDataValidate(data, companies, true)) {
              return reject();
            }

            delete data.submitted;
            resolve();
            // update firestore
            patchCompany(data);
            if (
              data.isAbleToCreateGuestPass === false &&
              data.isAbleToCreateGuestPass !== undefined
            ) {
              // console.log('isAbleToCreateGuestPass is positively False');
              users.forEach((ele) => {
                // console.log(ele);
                if (ele.companyId !== data.id) return;
                else {
                  let update = { ...ele };
                  update.isAbleToCreateGuestPass = false;
                  // console.log(update);
                  onRowUpdateUser(update);
                }
              });

              // firestore.collection("users").where('company_id', "==", data.id).get().then((DataSnap)=>{
              //   DataSnap.docs.forEach((ele)=>{
              //     console.log(ele.data())
              //     //firestore.collection("users").doc(ele.data().id).update({is_able_to_create_briefcase:false})
              //  })
              //})
            }
          }, 1000);
        } else resolve();
      } else {
        setTimeout(() => {
          data.submitted = true;

          // extra valid check for sanityDataset and sanityProjectId
          if (!companyDataValidate(data, companies, true)) {
            return reject();
          }

          delete data.submitted;
          resolve();
          // update firestore
          patchCompany(data);
        }, 1000);
      }
    });
  };

  // Update User
  const onRowUpdateUser = (data) => {
    // console.log('update', data);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        data.submitted = true;

        if (!userDataValidate(data)) {
          return reject();
        }
        delete data.submitted;
        resolve();
        // update firestore
        patchUser({ ...data });
      }, 1000);
    });
  };

  // Delete company
  const onRowDeleteCompany = (data) => {
    console.log('delete', data);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
        // update firestore
        deleteCompany(data.id);
      }, 1000);
    });
  };

  // Create sanity
  const handleSanityCreation = (data) => {
    // console.log('handle sanity creation');
    let isValid = sanityBuildValidate(data);
    if (isValid) {
      // call action
      buildSanity(data);
    } else {
      window.alert('Error: Not valid data to build sanity');
    }
  };

  // delete sanity
  const handleSanityDelete = (data) => {
    // show confirm
    let r = window.confirm('Are you sure you want to delete?');
    if (r === true) {
      deleteSanity(data.id);
    } else {
      // do nothing
    }
  };
  //--------------------
  // Columns
  //--------------------

  const NCName = React.useRef(<input />);
  const NCDomain = React.useRef();
  // company
  const tableColumns = [
    {
      title: 'Name',
      field: 'name',
      required: true,
      editComponent: (props) => (
        <input
          type="text"
          ref={NCName}
          value={props.value}
          onChange={(e) => {
            props.onChange(e.target.value);
            const shortened = e.target.value
              .toLowerCase()
              .replaceAll(' ', '-')
              .replace(/[^a-zA-Z0-9-_]/g, '')
              .trim();
            // NCDomain.current.focus();
            // NCName.current.focus();
            NCDomain.current.value = `https://${shortened}.f2bportfolio.com`;
            // NCName.current.focus();
            // NCDomain.current.focus();
            // let ev =document.createEvent("event")
            // ev.initEvent("change",true,false);
            // NCDomain.current.dispatchEvent(ev);
            // console.log(NCDomain.current)
            //NCDomain.current.props.onChange(`https://${e.target.value}-f2bportfolio.com`)
          }}
        />
      )
    },
    { title: 'Comment', field: 'comment', required: false },
    {
      title: 'Domain',
      field: 'studioDomain',
      required: true,
      editable: 'onAdd',
      editComponent: (props) => {
        // let b = <input ref={NCDomain} type="text" editable="false" />;
        let b = <input ref={NCDomain} type="text" />;
        // console.log(b);

        return b;
      },
      render: (rowData) => {
        return (
          <a
            href={rowData.studioDomain}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#000' }}
          >
            {rowData.studioDomain}
          </a>
        );
      }
    },
    {
      title: 'Sanity',
      field: 'sanityProjectId',
      required: true,
      editable: 'onUpdate',
      filtering: false,
      render: (rowData) => {
        if (rowData.sanityProjectId !== '') {
          return (
            <div style={{ display: 'block', whiteSpace: 'nowrap' }}>
              <span
                style={{
                  fontFamily: 'monospace',
                  display: 'inline-block',
                  marginRight: '25px'
                }}
              >
                {rowData.sanityProjectId}
              </span>
              <StyledButton
                state="delete"
                onClick={() => handleSanityDelete(rowData)}
              >
                Delete
              </StyledButton>
            </div>
          );
        }
        return (
          <StyledButton onClick={() => handleSanityCreation(rowData)}>
            Create Sanity Build
          </StyledButton>
        );
      },
      editComponent: (props) => {
        if (props.rowData.sanityProjectId !== '') {
          return (
            <StyledButton disabled>
              Delete Sanity Build {props.rowData.sanityProjectId}
            </StyledButton>
          );
        }
        return <StyledButton disabled>Create Sanity Build</StyledButton>;
      }
    },
    {
      title: 'Sanity API Token',
      field: 'sanityToken',
      required: false,
      editable: 'onUpdate',
      render: (rowData) => {
        if (rowData.sanityToken === '') {
          if (rowData.sanityProjectId) {
            const url = `https://manage.sanity.io/projects/${rowData.sanityProjectId}/settings/api`;
            return (
              <>
                Add a Read+Write Token
                <br />
                <a href={url} target="_blank" rel="noopener noreferrer">
                  Generate one here
                </a>
              </>
            );
          } else {
            return <>Please create a build first</>;
          }
        } else {
          return (
            <span
              style={{
                display: 'inline-block',
                width: '150px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              {rowData.sanityToken}
            </span>
          );
        }
      }
    },
    {
      title: 'Is Active?',
      field: 'isActive',
      required: true,
      editable: 'onUpdate',
      lookup: {
        isActiveTrue: 'True',
        isActiveFalse: 'False'
      }
    },
    {
      title: 'Owner',
      field: 'userName',
      editable: 'onUpdate',
      editComponent: (props) => {
        // default value
        let chosenOption = {
          label: props.rowData.userName,
          value: props.rowData.owner_id
        };
        return (
          <Select
            hideSelectedOptions={true}
            styles={styles}
            options={userOptions}
            onChange={props.onChange}
            defaultValue={chosenOption}
          />
        );
      }
    },
    {
      title: 'Users',
      field: 'users.total',
      filtering: false,
      editable: 'never',
      render: (rowData) => {
        if (!rowData.users) return 'check again.';
        if (rowData.users.total > 0) {
          return (
            rowData.users.total +
            ' (' +
            rowData.users.active +
            '/' +
            rowData.users.inactive +
            ')'
          );
        } else {
          return rowData.users.total;
        }
      }
    }
    //GP/briefcase non-distinctive patch
    // {
    //   title: 'Able to Use guest passes',
    //   field: 'isAbleToCreateGuestPass',
    //   type:"boolean",
    //   initialEditValue:true,
    //   //required: true,
    //   // lookup: {
    //   //   portfolioGuestPassTrue: 'True',
    //   //   portfolioGuestPassFalse: 'False'
    //   // }
    // }
  ];

  return (
    <StyledCompanyList>
      <StyledCompanyContent>
        <StyledTablesContainer>
          <MaterialTable
            minRows={20}
            options={{
              draggable: false,
              search: false,
              filtering: true,
              sorting: true,
              actionsColumnIndex: -1,
              pageSize: 20,
              pageSizeOptions: [0, 5, 10, 20, 40],
              rowStyle: (rowData) => ({
                color: rowData.disable ? '#AAA' : '#000'
              }),
              addRowPosition: 'first'
            }}
            editable={{
              isEditable: (rowData) => rowData.sanityProjectId !== '',
              isDeletable: (rowData) => rowData.sanityProjectId === '',
              onRowAdd: onRowAddCompany,
              onRowUpdate: onRowUpdateCompany,
              onRowDelete: onRowDeleteCompany
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'Loading...'
              }
            }}
            components={{
              EditField: (props) => {
                if (props.columnDef.required && props.rowData.submitted) {
                  return <MTableEditField {...props} error label="Required" />;
                }
                return <MTableEditField {...props} />;
              }
            }}
            icons={tableIcons}
            columns={tableColumns}
            data={companyTableData.map((obj) => ({ ...obj }))} // NOTE MaterialTable mutates data passed so give copy of table data
            title={`Company list (${companyCount})`}
          />
        </StyledTablesContainer>
      </StyledCompanyContent>
    </StyledCompanyList>
  );
};

export default CompanyList;
