import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import EngageChoiceBox from '../../components/_Engage/EngageChoiceBox';
import EngageCustomerBox from '../../components/_Engage/EngageCustomerBox';

const StyledEngagePage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.mode === 'training' ? 'rgb(170, 204, 238)' : 'rgb(7, 104, 176)'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EngagePage = ({
  auth,
  member,
  companyConfig,
  portfolio,
  images,
  mode,
  chosenPortfolioId,
  updateSessionKey,
  resetPortfolioMode,
  setPortfolioId,
  setPortfolioMode,
  setPortfolioCustomerInfo,
  setPortfolioSessionId,
  addSessionToSanity
}) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const firebase = useFirebase();
  const firestore = useFirestore();
  const history = useHistory();

  useEffect(() => {
    // Create dropdown content
    let options = [];
    portfolio.forEach((obj) => {
      options.push({ value: obj._id, label: obj.title });
    });
    setDropdownOptions(options);
  }, [portfolio]);

  const logout = () => {
    console.log('LOGOUT');
    firebase.logout();
    // clear sessionkey in local storage and redux session key
    updateSessionKey('');
    // reset portfolioReducer
    resetPortfolioMode();
  };

  // Create session, store to firestore
  const createSession = (newCustomerInfo) => {
    // check mode
    if (mode === 'default') {
      const session = {
        creator: member.name, // member/user name from sanity
        customer_name:
          newCustomerInfo.firstName + ' ' + newCustomerInfo.lastName,
        page_visited: [],
        user_id: auth.uid,
        portfolio_id: chosenPortfolioId,
        session_date: firestore.Timestamp.now()
      };

      // firestore set
      firestore
        .collection('sessions')
        .add(session)
        .then((docRef) => {
          // set session_id in portfolioReducer
          setPortfolioSessionId(docRef.id);

          //add sessions to sanity with session_id created by firestore
          addSessionToSanity({ ...session, session_id: docRef.id });

          // TODO make this go after firestore and sanity success?
          // go to portfolio
          history.push('/portfolio');
        })
        .catch((error) => console.error('Error adding document: ' + error));
    } else {
      // else in training so no need for session log
      setPortfolioSessionId('');
      history.push('/portfolio');
    }
  };

  return (
    <StyledEngagePage mode={mode}>
      {mode === '' && (
        <EngageChoiceBox
          dropdownOptions={dropdownOptions}
          setMode={setPortfolioMode}
          logout={logout}
          setPortfolioId={setPortfolioId}
          images={images}
          companyConfig={companyConfig}
        />
      )}
      {(mode === 'training' || mode === 'default') && (
        <EngageCustomerBox
          setMode={setPortfolioMode}
          logout={logout}
          setPortfolioCustomerInfo={setPortfolioCustomerInfo}
          createSession={createSession}
          images={images}
          companyConfig={companyConfig}
        />
      )}
    </StyledEngagePage>
  );
};

export default EngagePage;
