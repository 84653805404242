import { connect } from 'react-redux';
import CompanyList from './CompanyList';
import {
  createCompany,
  patchCompany,
  deleteCompany,
  buildSanity,
  patchUserAuto,
  deleteSanity
} from '../../actions';

const mapStateToProps = (state) => ({
  companies: state.companyReducer.companies,
  users: state.userReducer.users
});

const mapDispatchToProps = (dispatch) => {
  return {
    createCompany: (obj) => dispatch(createCompany(obj)),
    patchCompany: (obj) => dispatch(patchCompany(obj)),
    patchUser: (obj) => dispatch(patchUserAuto(obj)),
    deleteCompany: (id) => dispatch(deleteCompany(id)),
    buildSanity: (obj) => dispatch(buildSanity(obj)),
    deleteSanity: (id) => dispatch(deleteSanity(id))
  };
};

const CompanyListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyList);

export default CompanyListContainer;
