import {
  SET_PORTFOLIO_CUSTOMER_INFO,
  SET_PORTFOLIO_ID,
  SET_PORTFOLIO_MODE,
  RESET_PORTFOLIO_INFO,
  SET_PORTFOLIO_SESSION_ID
} from '../constants/actionTypes';

export const setPortfolioCustomerInfo = (obj) => ({
  type: SET_PORTFOLIO_CUSTOMER_INFO,
  obj
});

export const setPortfolioId = (id) => ({
  type: SET_PORTFOLIO_ID,
  id
});

export const setPortfolioMode = (mode) => ({
  type: SET_PORTFOLIO_MODE,
  mode
});

export const setPortfolioSessionId = (id) => ({
  type: SET_PORTFOLIO_SESSION_ID,
  id
});

export const resetPortfolioMode = () => ({
  type: RESET_PORTFOLIO_INFO
});
