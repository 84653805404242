import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoginForm from './LoginForm';
import ForgotPasswordForm from '../ForgotPasswordForm';
import Logo from '../../../assets/images/logo.png';

const StyledLoginBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 900px;
  width: 1421px;
  height: 725px;
  border-radius: 50px;
  background-color: white;
`;

const StyledLoginLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  padding: 25px;

  img {
    width: calc(100% - 48px);
    height: auto;
  }
`;

const StyledLoginFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
`;

const LoginBox = ({ updateSessionKey }) => {
  const [forgotPassMode, setForgotPassMode] = useState(false);

  return (
    <StyledLoginBox>
      <StyledLoginLogo>
        <img src={Logo} alt="logo" />
      </StyledLoginLogo>
      <StyledLoginFormContainer>
        {forgotPassMode ? (
          <ForgotPasswordForm />
        ) : (
          <LoginForm
            setForgotPassMode={setForgotPassMode}
            updateSessionKey={updateSessionKey}
          />
        )}
      </StyledLoginFormContainer>
    </StyledLoginBox>
  );
};

LoginBox.propTypes = {
  content: PropTypes.array,
  error: PropTypes.object,
  updateSessionKey: PropTypes.func
};

export default LoginBox;
