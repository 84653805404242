import {
  GET_USERS,
  CREATE_USER,
  PATCH_USER,
  DELETE_USER
} from '../constants/actionTypes';

const initialState = {
  users: []
};

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS.SUCCESS:
      return {
        ...state,
        users: [...action.array]
      };
    case CREATE_USER.SUCCESS:
      return {
        ...state,
        users: [...state.users, action.obj]
      };
    case PATCH_USER.SUCCESS:
      return {
        ...state,
        users: state.users.map((obj) => {
          // find modified/patched obj
          if (obj.id === action.obj.id) {
            return action.obj;
          }
          return obj;
        })
      };
    case DELETE_USER.SUCCESS:
      return {
        ...state,
        users: state.users.filter((obj) => {
          return obj.id !== action.id;
        })
      };
    default:
      return state;
  }
};
